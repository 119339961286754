import React, { useState, useEffect } from "react";
import axios from "axios";
import AddInquiry from "./AddInquiry";
import InquiryDesc from "./InquiryDesc";
import EditInquiry from './NextInquiry';
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import SearchBar from "../../components/sidebar/SearchBar";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner'; // Spinner
import 'react-toastify/dist/ReactToastify.css';

function ConvertedList({ handleLogout, username }) {
    const [isLoading, setIsLoading] = useState(false);
    const [inquiries, setInquiries] = useState([]);
    const [selectedInquiry, setSelectedInquiry] = useState(null);
    const [showInquiryDetails, setShowInquiryDetails] = useState(false);
    const [isAddInquiryModalOpen, setIsAddInquiryModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editInquiry, setEditInquiry] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [deleteInquiry, setDeleteInquiry] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");

    useEffect(() => {
        fetchInquiries();
    }, []);

    const fetchInquiries = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/inquiries`);
            const sortedInquiries = response.data.filter(item => item.status === "converted");
            setInquiries(sortedInquiries);
        } catch (error) {
            console.error("Error fetching inquiries:", error);
        }
    };

    const handleAddInquiry = () => {
        setIsAddInquiryModalOpen(true);
    };

    const handleCloseInquiryModal = () => {
        setIsAddInquiryModalOpen(false);
        setIsEditModalOpen(false);
    };

    const handleInquiryDetails = (inquiry) => {
        setSelectedInquiry(inquiry);
        setShowInquiryDetails(true);
    };

    const handleEditInquiryClick = (inquiry) => {
        setEditInquiry(inquiry);
        setSelectedInquiry(inquiry);
        setIsEditModalOpen(true);
    };

    const handleEditInquiryClose = () => {
        setSelectedInquiry(null);
    };

    const handleBackToTable = () => {
        setSelectedInquiry(null);
        setShowInquiryDetails(false);
    };

    const handleDeleteInquiry = (inquiry) => {
        setDeleteInquiry(inquiry);
        setIsDeleteModalOpen(true);
    };

    const handleUpdateInquiry = async (updatedInquiry) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/converted_inquiries/${updatedInquiry.id}`, updatedInquiry);
            console.log("Inquiry updated:", response.data);
            const updatedInquiryList = inquiries.map(inq => (inq.id === updatedInquiry.id ? response.data : inq));
            const sortedInquiries = updatedInquiryList.sort((a, b) => b.id - a.id);
            setInquiries(sortedInquiries);
        } catch (error) {
            console.error("Error updating inquiry:", error);
        }
    };

    const handleDeleteConfirmation = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/converted_inquiries/${deleteInquiry.id}`);

            const deletedInquiry = { ...deleteInquiry, reason: deleteReason };
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedInquiry);

            setInquiries((prevInquiries) =>
                prevInquiries.filter((inq) => inq.id !== deleteInquiry.id)
            );
            setIsDeleteModalOpen(false);

            console.log("Inquiry deleted successfully");
        } catch (error) {
            console.error("Error deleting inquiry:", error);
        }
    };

    const onUpdateinquery = () => {
        toast.success("Data Uploaded Successfully")
        fetchInquiries();
    }
    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = inquiries.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className='d-flex w-100 h-100 bg-white'>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                <ToastContainer />
                    {showInquiryDetails && selectedInquiry ? (
                        <InquiryDesc inquiry={selectedInquiry} onClose={handleBackToTable} />
                    ) : (
                    <div className="row">
                        <div className="col-xl-12">
                            <div style={{ borderRadius: "20px", border: "1px solid #003459" }} className='overflow-hidden'>
                                <div style={{background: "rgb(33,131,128)", background: "#00509d " }} className="row no-gutters align-items-center p-3">
                                    <div className="col">
                                        <div className="text-xs font-weight-bold text-white text-uppercase d-flex align-items-center justify-content-between" style={{ fontSize: '1.5rem' }}>
                                            <div className="nunito text-white">Converted Inquiries List</div>
                                            <button onClick={handleAddInquiry} className="button_details"><i className="fa fa-plus"></i> Add New Inquiry
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <hr className='m-0 p-0' />
                                <div className=''>
                                    <div className="card-body">
                                        <div className="" style={{ maxHeight: "610px", overflowY: "auto" }}>
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <ThreeDots color="#00509d" height={80} width={80} />
                                                </div>
                                            ) : (
                                                <table className="table table-bordered" style={{ width: "100%" }}>
                                                    <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                        <tr>
                                                            <th>Customer Name</th>
                                                            <th>Call Date</th>
                                                            <th>Mobile No</th>
                                                            <th>Executive Name</th>
                                                            <th>Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <style>
                                                            {`.hyperlink:hover {color: blue;}`}
                                                        </style>
                                                        {currentItems.length === 0 ? (
                                                            <tr>
                                                                <td colSpan="12" className="text-center">No Inquiry Entries.</td>
                                                            </tr>
                                                        ) : (
                                                            currentItems.map((inquiry, index) => (
                                                                <tr key={index}>
                                                                    <td className='hyperlink' style={{ cursor: "pointer" }} onClick={() => handleInquiryDetails(inquiry)}>{inquiry.customerName}</td>
                                                                    <td>{formatDate(inquiry.callDate)}</td>
                                                                    <td>{inquiry.mobileNo}</td>
                                                                    <td>{inquiry.callAttendExecutiveName}</td>
                                                                    <td className="text-end">&#x20B9;{inquiry.amount != null ? inquiry.amount.toFixed(2) : '0.00'}</td>
                                                                </tr>
                                                            ))
                                                        )}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                        <ul className="pagination mt-2">
                                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                            </li>
                                            {Array.from({ length: Math.ceil(inquiries.length / itemsPerPage) }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(inquiries.length / itemsPerPage) && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* {showInquiryDetails && selectedInquiry && (
                                    <InquiryDesc
                                        inquiry={selectedInquiry}
                                        onClose={handleBackToTable}
                                    />
                                )} */}
                                {selectedInquiry && !showInquiryDetails && (
                                    <EditInquiry inquiry={selectedInquiry} onClose={handleEditInquiryClose} onUpdate={handleUpdateInquiry} />
                                )}
                                {isAddInquiryModalOpen && <AddInquiry onClose={handleCloseInquiryModal} onUpdate={onUpdateinquery} />}
                                <DeleteConfirmationModal
                                    isOpen={isDeleteModalOpen}
                                    itemName={deleteInquiry ? deleteInquiry.clientName : ""}
                                    onDelete={handleDeleteConfirmation}
                                    onClose={() => setIsDeleteModalOpen(false)}
                                    deleteReason={deleteReason}
                                    setDeleteReason={setDeleteReason}
                                />
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ConvertedList;



















