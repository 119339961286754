import React, { useState, useEffect } from "react";
import axios from 'axios';
import imageCompression from 'browser-image-compression';

const AddInward = ({ onClose, onUpdate }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        entityName: "",
        code: "",
        address: "",
        invoice: "",
        courierCompany: "",
        consignmentNo: "",
        consignmentdate: "",
        shipFrom: "",
        shipTo: "",
        shipfromaddress: "",
        country: 'IN',
        city: '',
        state: '',
        pincode: "",
        receiptDate: "",
        receiptBy: "",
        consignmentType: "",
        photo: null,
        remark: "",
        office_id: "" ,// Added office_id field to formData
        username:localStorage.getItem('username'),
    });
    const [clients, setClients] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [courierCompany, setCourierCompany] = useState([]);
    const [ourOffice, setOurOffice] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [states, setStates] = useState([]);
    const [errors, setErrors] = useState({});
    const [isClient, setIsClient] = useState(true);

    useEffect(() => {
        fetchClients();
        fetchVendors();
        fetchCourierCompany();
        fetchOurOffice();
        fetchEmployees();
        fetchStates();
    }, []);

    const fetchClients = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/clients`);
            setClients(response.data);
        } catch (error) {
            console.error("Error fetching Clients:", error);
        }
    };

    const fetchVendors = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/vendors`);
            setVendors(response.data);
        } catch (error) {
            console.error("Error fetching Vendors:", error);
        }
    };

    const fetchCourierCompany = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/couriers`);
            setCourierCompany(response.data);
        } catch (error) {
            console.error("Error fetching courier Company:", error);
        }
    };

    const fetchOurOffice = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/offices`);
            setOurOffice(response.data);
        } catch (error) {
            console.error("Error fetching our offices:", error);
        }
    };

    const fetchEmployees = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
            setEmployees(response.data);
        } catch (error) {
            console.error("Error fetching Employees:", error);
        }
    };

    const fetchStates = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/states`);
            setStates(response.data);
        } catch (error) {
            console.error("Error fetching States:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "entityName") {
            const selectedEntity = isClient
                ? clients.find(entity => entity.clientName === value)
                : vendors.find(entity => entity.vendorName === value);

            setFormData({
                ...formData,
                [name]: value,
                client_Id: isClient && selectedEntity ? selectedEntity.id : "",
                vendor_Id: !isClient && selectedEntity ? selectedEntity.id : "",
                code: selectedEntity ? selectedEntity.clientCode || selectedEntity.vendorCode : "",
                emailId1: selectedEntity ? selectedEntity.emailId1 || selectedEntity.emailId1 : "",
                address: selectedEntity
                    ? `${selectedEntity.address1 || selectedEntity.address}, ${selectedEntity.clientCity1 || selectedEntity.city}, ${selectedEntity.clientState1 || selectedEntity.state}, ${selectedEntity.clientPincode1 || selectedEntity.pincode}`
                    : ""
            });
        } else if (name === "courierCompany") {
            const selectedCourier = courierCompany.find(company => company.courierCompanyName === value);
            setFormData({
                ...formData,
                [name]: value,
                courierCompany_id: selectedCourier ? selectedCourier.id : "",
            });
        } else if (name === "shipTo") {
            const selectedShipTo = ourOffice.find(office => office.officeName === value);
            setFormData({
                ...formData,
                [name]: value,
                office_id: selectedShipTo ? selectedShipTo.id : "", // Set office_id based on selected office
            });
        } else if (name === "ename") {
            const selectedEmployee = employees.find(employee => employee.ename === value);
            setFormData({
                ...formData,
                [name]: value,
                employee_id: selectedEmployee ? selectedEmployee.id : "",
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };


    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        try {
            const options = {
                maxSizeMB: 0.2,
                maxWidthOrHeight: 800,
                useWebWorker: true,
            };

            const compressedFile = await imageCompression(file, options);

            // Create a new File object with the compressed file and original file name
            const fileWithMetaData = new File([compressedFile], file.name, { type: compressedFile.type });

            setFormData({
                ...formData,
                photo: fileWithMetaData, // Set the compressed file with metadata
            });
        } catch (error) {
            console.error('Image compression error:', error);
        }
    };

    const validate = () => {
        let formErrors = {};
        if (!formData.code) formErrors.clientCode = 'Client Code is required';
        if (!formData.address) formErrors.address = 'Contact Person Name is required';

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (!validate()) {
            return;
        }
        try {
            const formDataToSend = new FormData();
            for (const key in formData) {
                // Do not append photo key
                if (key !== 'photo') {
                    formDataToSend.append(key, formData[key]);
                }
            }
            // Append the compressed file to the form data
            formDataToSend.append("photo", formData.photo);

            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/addInward`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log("Add Inward data uploaded successfully:", response.data);
            onUpdate(formData);
            setTimeout(() => {
                onClose();
                window.location.reload(); // Reload the page after submission
            }, 1000);
            setFormData({
                entityName: "",
                code: "",
                address: "",
                invoice: "",
                courierCompany: "",
                consignmentNo: "",
                consignmentdate: "",
                shipFrom: "",
                shipTo: "",
                shipfromaddress: "",
                country: 'IN',
                city: '',
                state: '',
                pincode: "",
                receiptDate: "",
                receiptBy: "",
                consignmentType: "",
                photo: null,
                remark: "",
                office_id: "" // Reset office_id
            });
            onUpdate();
            setTimeout(() => {
                onClose();
                window.location.reload(); // Reload the page after submission
            }, 1000);
        } catch (error) {
            console.error("Error adding inward data:", error);
        }finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div id="addClient" className="modal fade show" role="dialog" style={{ display: "block"}}>
           <div style={{ borderRadius: "20px" }} className="modal-dialog modal-lg overflow-hidden">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} encType="multipart/form-data" autoComplete="off" noValidate="novalidate">
                    <div style={{ backgroundColor: "#00509D", color: "white" }} className="modal-header">
                            <h5 className="modal-title">Add Inward</h5>
                            <button type="button" className="button_details " onClick={handleClose}>
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                            <div className="form-group">
                                <label htmlFor="entityType">Entity Type</label>
                                <select
                                    name="entityType"
                                    id="entityType"
                                    className="form-control"
                                    value={isClient ? "Client" : "Vendor"}
                                    onChange={(e) => setIsClient(e.target.value === "Client")}
                                >
                                    <option value="Client">Client</option>
                                    <option value="Vendor">Vendor</option>
                                </select>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-8">
                                    <label htmlFor="entityName">{isClient ? "Client" : "Vendor"} Name <span style={{ color: "red" }}>*</span></label>
                                    <select
                                        name="entityName"
                                        id="entityName"
                                        className="form-control"
                                        value={formData.entityName}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="" disabled hidden>Select {isClient ? "Client" : "Vendor"}</option>
                                        {(isClient ? clients : vendors).map((entity) => (
                                            <option key={entity.id} value={isClient ? entity.clientName : entity.vendorName}>
                                                {isClient ? entity.clientName : entity.vendorName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="code">{isClient ? "Client" : "Vendor"} Code <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        type="text"
                                        id="code"
                                        name="code"
                                        className={`form-control ${errors.clientName ? 'is-invalid' : ''}`}
                                        value={formData.code}
                                        placeholder={isClient ? "Client Code" : "Vendor Code"}
                                        readOnly
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="address">Address <span style={{ color: "red" }}>*</span></label>
                                <input
                                    type="text"
                                    id="address"
                                    name="address"
                                    className={`form-control ${errors.clientName ? 'is-invalid' : ''}`}
                                    value={formData.address}
                                    placeholder="Address"
                                    readOnly
                                />
                            </div>
                            <hr />
                            <div className="form-group">
                                <label htmlFor="consignmentType">Consignment Subject<span style={{ color: "red" }}>*</span></label>
                                <input
                                    type="text"
                                    id="consignmentType"
                                    name="consignmentType"
                                    className={`form-control ${errors.clientName ? 'is-invalid' : ''}`}
                                    value={formData.consignmentType}
                                    onChange={handleChange}
                                    required
                                    placeholder="Consignment Subject"
                                />
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="invoice">Invoice Number </label>
                                    <input
                                        type="text"
                                        id="invoice"
                                        name="invoice"
                                        className="form-control"
                                        value={formData.invoice}
                                        onChange={handleChange}
                                        placeholder="Invoice Number"
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="courierCompany">Courier Company <span style={{ color: "red" }}>*</span></label>
                                    <select
                                        name="courierCompany"
                                        id="courierCompany"
                                        className={`form-control ${errors.clientName ? 'is-invalid' : ''}`}
                                        value={formData.courierCompany}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="" disabled hidden>Select Courier Company</option>
                                        {courierCompany.map((company) => (
                                            <option key={company.id} value={company.courierCompanyName}>
                                                {company.courierCompanyName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="consignmentNo">Consignment No <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        type="text"
                                        id="consignmentNo"
                                        name="consignmentNo"
                                        className={`form-control ${errors.clientName ? 'is-invalid' : ''}`}
                                        value={formData.consignmentNo}
                                        onChange={handleChange}
                                        placeholder="Consignment No."
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="consignmentdate">Consignment Date <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        type="date"
                                        id="consignmentdate"
                                        name="consignmentdate"
                                        className={`form-control ${errors.clientName ? 'is-invalid' : ''}`}
                                        value={formData.consignmentdate}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <label htmlFor="">Ship From / Address: - <span style={{ color: "red" }}>*</span></label>
                            <div className="form-group">
                                <label>Address :<span style={{ color: "red" }}>*</span></label>
                                <input
                                    name="shipfromaddress"
                                    type="text"
                                    className={`form-control ${errors.clientName ? 'is-invalid' : ''}`}
                                    value={formData.shipfromaddress}
                                    onChange={handleChange}
                                    required
                                    placeholder="Address"
                                />
                            </div>
                            <div className="form-row">

                                <div className="form-group col-md-4">
                                    <label>state <span style={{ color: "red" }}>*</span></label>
                                    <select
                                        name="state"
                                        id="state"
                                        className={`form-control ${errors.clientName ? 'is-invalid' : ''}`}
                                        value={formData.state}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="" disabled hidden>Select State</option>
                                        {states.map(state => (
                                            <option key={state.id} value={`${state.statename}`}>{state.statename}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>City <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="city"
                                        type="text"
                                        className={`form-control ${errors.clientName ? 'is-invalid' : ''}`}
                                        value={formData.city}
                                        onChange={handleChange}
                                        required
                                        placeholder="City"
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Pincode </label>
                                    <input
                                        name="pincode"
                                        type="text"
                                        className="form-control"
                                        value={formData.pincode}
                                        onChange={handleChange}
                                        required
                                        placeholder="Pincode"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="shipTo">Recived at Office <span style={{ color: "red" }}>*</span></label>
                                <select
                                    name="shipTo"
                                    id="shipTo"
                                    className={`form-control ${errors.clientName ? 'is-invalid' : ''}`}
                                    value={formData.shipTo}
                                    onChange={handleChange}
                                    required
                                    placeholder="Ship To"
                                >
                                    <option value="" disabled hidden>Select</option>
                                    {ourOffice.map((office) => (
                                        <option key={office.id} value={office.officeName}>
                                            {office.officeName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="receiptDate">Received Date <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        type="date"
                                        id="receiptDate"
                                        name="receiptDate"
                                        className={`form-control ${errors.clientName ? 'is-invalid' : ''}`}
                                        value={formData.receiptDate}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="ename">Received By <span style={{ color: "red" }}>*</span></label>
                                    <select
                                        name="ename"
                                        id="ename"
                                        className={`form-control ${errors.clientName ? 'is-invalid' : ''}`}
                                        value={formData.ename}
                                        onChange={handleChange}
                                        required
                                        placeholder="Employee Name "
                                    >
                                        <option value="" >Select</option>
                                        {employees.map((employee) => (
                                            <option key={employee.id} value={employee.ename}>
                                                {employee.ename}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="remark">Remark <span style={{ color: "red" }}>*</span></label>
                                <textarea
                                    id="remark"
                                    name="remark"
                                    className={`form-control ${errors.clientName ? 'is-invalid' : ''}`}
                                    value={formData.remark}
                                    onChange={handleChange}
                                    rows="3"
                                    placeholder="Remark "
                                ></textarea>
                            </div>
                            <div className="form-group">
                                <label htmlFor="photo">Upload Document</label>
                                <input
                                    type="file"
                                    id="photo"
                                    name="photo"
                                    className="form-control"
                                    onChange={handleImageChange}
                                    required
                                />
                                <small>Max Size : 200KB</small>
                            </div>
                        </div>
                        <div style={{ backgroundColor: "#00509D", color: "white" }} className="modal-footer">
                         <button type="submit" className="button_details" disabled={isLoading}>
                             {isLoading ? 'Loading...' : 'Submit'}
                         </button>
                         <button type="button" className="button_details" onClick={handleClose}>Close</button>
                     </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddInward;












