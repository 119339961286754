import React, { useEffect, useState, } from "react";
import { Link, useLocation, Route, Routes, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddEmployeeTable from "../../pages/EmployeeMaster/AddEmployeeTable";
import AddVendor from "../../pages/VendorMaster/AddVendor";
import AddClientModal from "../../pages/ClientMaster/AddClientModal";
import AddInward from "../../pages/Inword/AddInward";
import AddOutward from "../../pages/Outward/AddOutWard";
import AddCourier from "../../pages/CourierMaster/AddCourier";
import AddServiceProductModal from "../../pages/Services_Products/AddServiceProductModal";
import AddOfficeForm from "../../pages/OurOfficeMaster/AddOfficeForm";
import AddCompany from "../../pages/Company Master/AddCompany";
import AddSales from "../../pages/SalesMaster/AddSales";
import AddPurchase from "../../pages/PurchaseMaster/AddPurchase";
import AddInquiry from "../../pages/Salesfunal/AddInquiry";
import myLogo from '../../images/brand_images/OmsTras.png';
import footerLogo from '../../images/brand_images/OmsTras.png';

function Sidebar() {

  // Form Modal open 
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal open/close
  const [isAssetModalOpen, setIsAssetModalOpen] = useState(false); // State to manage modal open/close
  const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);
  const [isVendorModalOpen, setIsVendorModalOpen] = useState(false);
  const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);
  // addinward modal 
  const [isAddInwardModalopen, setIsAddInwardModalopen] = useState(false);
  // add outwardModal 
  const [isAddOutwardModalOpen, setIsAddOutwardModalOpen] = useState(false);
  // add Courier 
  const [isAddCourierModalOpen, setIsAddCourierModalOpen] = useState(false);
  // Service and product Modal 
  const [isAddServicesProductsModalOpen, setIsAddServicesProductsModalOpen] = useState(false);
  // Office Modal 
  const [isAddOfficeModalOpen, setIsAddOfficeModalOpen] = useState(false);
  // Company Modal 
  const [isAddCompanyModalOpen, setIsAddCompanyModalOpen] = useState(false);
  // Sales Modal 
  const [isAddSalesModalOpen, setIsAddSalesModalOpen] = useState(false);
  // purchase Modal 
  const [isAddPurchaseModalOpen, setIsAddPurchaseModalOpen] = useState(false);
  // AddInquiry 
  const [isAddInquiryModalOpen, setIsAddInquiryModalOpen] = useState(false);

  const location = useLocation();

  const [style, setStyle] = useState("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");

  const changeStyle = () => {
    if (style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion") {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled");
    }
    else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion")
    }
  };
  const changeStyle1 = () => {
    if (style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion") {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1");
    }
    else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion")
    }
  };

  const navigate = useNavigate(); // Place the hook outside of the component


  // Handle 
  const handleAddAsset = () => {
    setIsModalOpen(true); // Open the modal when "Add new Asset" button is clicked
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };
  const handleAddEmployee = () => {
    setIsEmployeeModalOpen(true);
  };

  const handleAddVendor = () => {
    setIsVendorModalOpen(true);
  };

  const handleAddAddInquiry = () => {
    setIsAddInquiryModalOpen(true);
  };

  const handleCloseAddInquiryModal = () => {
    setIsAddInquiryModalOpen(false);
  };

  const handleCloseEmployeeModal = () => {
    setIsEmployeeModalOpen(false);
  };

  const handleCloseVendorModal = () => {
    setIsVendorModalOpen(false);
  };

  // clientmodal 

  const handleAddClient = () => {
    setIsAddClientModalOpen(true);
  };

  const handleCloseClientModal = () => {
    setIsAddClientModalOpen(false);
  };

  // Asset Lost 

  const handleAddOffice = () => {
    setIsAddOfficeModalOpen(true);
  };

  const handleCloseOfficeModal = () => {
    setIsAddOfficeModalOpen(false);
  };

  // Purchase Modal 

  const handleAddPurchase = () => {
    setIsAddPurchaseModalOpen(true)
  };

  const handleClosePurchaseModal = () => {
    setIsAddPurchaseModalOpen(false);
  };

  // Brand  Modal 

  const handleAddServicesProducts = () => {
    setIsAddServicesProductsModalOpen(true);
  };

  const handleCloseServicesProductsModal = () => {
    setIsAddServicesProductsModalOpen(false);
  };

  // Add Inward Modal 

  const handleAddInward = () => {
    setIsAddInwardModalopen(true);
  };

  const handleCloseInwardModal = () => {
    setIsAddInwardModalopen(false);
  };

  // Sales List Modal 

  const handleAddSalesModal = () => {
    setIsAddSalesModalOpen(true);
  };

  const handleCloseSalesModal = () => {
    setIsAddSalesModalOpen(false);
  };

  // Asset Insurence Modal 

  const handleAddCompanyModal = () => {
    setIsAddCompanyModalOpen(true);
  };

  const handleCloseCompanyModal = () => {
    setIsAddCompanyModalOpen(false);
  };
  // Asser Maintenance Modal 

  const handleAddCourierModal = () => {
    setIsAddCourierModalOpen(true);
  };

  const handleCloseCourierModal = () => {
    setIsAddCourierModalOpen(false);
  };

  // Out Ward Modal 

  const handleAddOutWardModal = () => {
    setIsAddOutwardModalOpen(true);
  };

  const handleCloseOutWardModal = () => {
    setIsAddOutwardModalOpen(false);
  };

  // Add Asset Modal 

  const handleAddAssetModal = () => {
    setIsAssetModalOpen(true);
  };

  const handleCloseAssetModal = () => {
    setIsAssetModalOpen(false);
  };

  const handleListClick = (path) => {
    if (window.location.pathname === path) {
      window.location.reload();
    }
  };

  const [dashboardLogo, setDashboardLogo] = useState([]);

  useEffect(() => {
    const fetchDashboardLogo = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
        setDashboardLogo(response.data);
      } catch (error) {
        console.error('Error fetching Dashboard Logo', error);
      }
    };

    fetchDashboardLogo();
  }, []);

  const handleUpdate = () => {
    toast.success("successfully uploaded");
    // window.location.reload();
  }

  return (
    <div style={{ position: "relative" }}>
      <body className={style} id="accordionSidebar">
        {/*  <!-- Sidebar --> */}
        <div style={{ height: "100%" }} className="d-flex flex-column">
          <ul>
            {/*  <!-- Sidebar - Brand --> */}
            <a className="sidebar-brand d-flex align-items-center justify-content-center gap-2 px-3 py-1" href="#">
              <div className="sidebar-brand-text" style={{ width: "100%", height: "100%" }}>
                <div className='logoo'>
                  <img
                    src={dashboardLogo.landingPageLogo
                      ? `${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${dashboardLogo.landingPageLogo}`
                      : myLogo}
                    className='img-logo'
                  />
                </div>
              </div>
              <div className="text-center d-none d-md-inline ">
                <button className="rounded-circle border-0" id="sidebarToggle" onClick={changeStyle}></button>
              </div>
            </a>
            {/*   <!-- Divider --> */}
            <hr className="sidebar-divider my-0" />
            {/*  <!-- Nav Item - Dashboard --> */}
            <li style={{ zIndex: "999" }} className="nav-item active">
              <Link to="/dashboard" className="nav-link">
                <i className="fas fa-fw fa-tachometer-alt"></i>
                <span>Dashboard</span>
              </Link>
            </li>
            {/*  <!-- Divider --> */}
            <hr className="sidebar-divider" />
            {/* <!-- Nav Item - Company --> */}
            <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsesalesfunnel"
                aria-expanded="true" aria-controls="collapsesalesfunnel">
                <i class="fa-solid fa-briefcase"></i>
                <span>Sales funnel</span>
              </a>
              <div id="collapsesalesfunnel" className="collapse" aria-labelledby="headingCompany" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Sales Funnel:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddAddInquiry}>Add New Inquiry</a>
                  <Link to="/inquiryList" className="collapse-item" onClick={() => handleListClick("/inquiryList")}>
                    <span>List of Inquiry </span>
                  </Link>
                  <Link to="/convertedinquirylist" className="collapse-item" onClick={() => handleListClick("/convertedinquirylist")}>
                    <span>List of Converted </span>
                  </Link>
                  <Link to="/unconvertedinquirylist" className="collapse-item" onClick={() => handleListClick("/unconvertedinquirylist")}>
                    <span>List of Unconverted </span>
                  </Link>
                </div>
              </div>
            </li>
            {/* <!-- Nav Item - Client --> */}
            <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseclient"
                aria-expanded="true" aria-controls="collapseclient">
                <i class="fa-solid fa-building"></i>
                <span>Client Master</span>
              </a>
              <div id="collapseclient" className="collapse" aria-labelledby="headingCompany" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Client Master:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddClient}>Add New Client </a>
                  <Link to="/clientList" className="collapse-item" onClick={() => handleListClick("/clientList")}>
                    <span>Client List</span>
                  </Link>
                </div>
              </div>
            </li>
            {/* <!-- Nav Item - vendor --> */}
            <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsevendor"
                aria-expanded="true" aria-controls="collapsevendor">
                <i class="fa-solid fa-building"></i>
                <span>Vendor Master</span>
              </a>
              <div id="collapsevendor" className="collapse" aria-labelledby="headingCompany" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Vendor Master:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddVendor}>Add New Vendor</a>
                  <Link to="/vendorlist" className="collapse-item" onClick={() => handleListClick("/vendorlist")}>
                    <span>Vendor List</span>
                  </Link>
                </div>
              </div>
            </li>
            {/* <!-- Nav Item - Purchase --> */}
            <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsesalespurchase"
                aria-expanded="true" aria-controls="collapsesalespurchase">
                <i class="fa-solid fa-building"></i>
                <span>Sales/Purchase Master</span>
              </a>
              <div id="collapsesalespurchase" className="collapse" aria-labelledby="headingCompany" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Sales Master:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddSalesModal}>Add New Sales</a>
                  <Link to="/saleslist" className="collapse-item" onClick={() => handleListClick("/saleslist")}>
                    <span>Sales List</span>
                  </Link>
                  <h6 className="collapse-header">Purchase Master:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddPurchase}>Add New Purchase</a>
                  <Link to="/purchaselist" className="collapse-item" onClick={() => handleListClick("/purchaselist")}>
                    <span>Purchase List</span>
                  </Link>
                </div>
              </div>
            </li>
            {/* <!-- Nav Item - In ward and Outward --> */}
            <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseinoutward"
                aria-expanded="true" aria-controls="collapseinoutward">
                <i class="fa-solid fa-building"></i>
                <span>In/Outward Master</span>
              </a>
              <div id="collapseinoutward" className="collapse" aria-labelledby="headingCompany" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Inward Master:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddInward}>Add New Inward</a>
                  <Link to="/inwardlist" className="collapse-item" onClick={() => handleListClick("/inwardlist")}>
                    <span>Inward List</span>
                  </Link>
                  <h6 className="collapse-header">Outward Master:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddOutWardModal}>Add New Outward</a>
                  <Link to="/outwardlist" className="collapse-item" onClick={() => handleListClick("/outwardlist")}>
                    <span>Outward List</span>
                  </Link>
                  <h6 className="collapse-header">Courier Company:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddCourierModal}>Add Courier Company</a>
                  <Link to="/courierlist" className="collapse-item" onClick={() => handleListClick("/courierlist")}>
                    <span>Courier Company List</span>
                  </Link>
                </div>
              </div>
            </li>
            {/* <!-- Nav Item - Service Product --> */}
            <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseserviceproduct"
                aria-expanded="true" aria-controls="collapseserviceproduct">
                <i class="fa-solid fa-building"></i>
                <span>Service/Product Master</span>
              </a>
              <div id="collapseserviceproduct" className="collapse" aria-labelledby="headingCompany" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Service/Product Master:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddServicesProducts}>Add Service/Product</a>
                  <Link to="/servicelist" className="collapse-item" onClick={() => handleListClick("/servicelist")}>
                    <span>Service List</span>
                  </Link>
                  <Link to="/productlist" className="collapse-item" onClick={() => handleListClick("/productlist")}>
                    <span>Product List</span>
                  </Link>
                </div>
              </div>
            </li>
            {/* <!-- Nav Item - Office Master --> */}
            <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsecompanyoffice"
                aria-expanded="true" aria-controls="collapsecompanyoffice">
                <i class="fa-solid fa-building"></i>
                <span>Company/Office Master</span>
              </a>
              <div id="collapsecompanyoffice" className="collapse" aria-labelledby="headingCompany" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Company Master:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddCompanyModal}>Add New Company</a>
                  <Link to="/companylist" className="collapse-item" onClick={() => handleListClick("/companylist")}>
                    <span>Company List</span>
                  </Link>
                  <h6 className="collapse-header">Office Master:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddOffice}>Add New Office</a>
                  <Link to="/OfficeList" className="collapse-item" onClick={() => handleListClick("/OfficeList")}>
                    <span>Office List</span>
                  </Link>
                </div>
              </div>
            </li>
            {/* <!-- Nav Item - Employee Master --> */}
            <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseemployee"
                aria-expanded="true" aria-controls="collapseemployee">
                <i class="fa-solid fa-building"></i>
                <span>Employee Master</span>
              </a>
              <div id="collapseemployee" className="collapse" aria-labelledby="headingCompany" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Employee Master:</h6>

                  <a className="collapse-item" href="#" onClick={handleAddEmployee}>Add New Employee</a>
                  <Link to="/employeelist" className="collapse-item" onClick={() => handleListClick("/employeelist")}>
                    <span>Employee List</span>
                  </Link>
                </div>
              </div>
            </li>
            {/* <!-- Nav Item - Setting Master --> */}
            <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsesetting"
                aria-expanded="true" aria-controls="collapsesetting">
                <i class="fa-solid fa-building"></i>
                <span>Setting Master</span>
              </a>
              <div id="collapsesetting" className="collapse" aria-labelledby="headingCompany" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Setting Master:</h6>

                  <Link to="/applicationsetting" className="collapse-item" onClick={() => handleListClick("/applicationsetting")}>
                    <span>Application Setting </span>
                  </Link>
                  {/* <Link to="/profile" className="collapse-item" onClick={() => handleListClick("/profile")}>
                    <span>Profile </span>
                  </Link> */}
                </div>
              </div>
            </li>
            {/* <!-- Nav Item - Setting Master --> */}
            <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsereport"
                aria-expanded="true" aria-controls="collapsereport">
                <i class="fa-solid fa-building"></i>
                <span>Report Master</span>
              </a>
              <div id="collapsereport" className="collapse" aria-labelledby="headingCompany" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Report Master:</h6>

                  <Link to="/employeereport" className="collapse-item" onClick={() => handleListClick("/employeereport")}>
                    <span>Employee Report</span>
                  </Link>

                  <Link to="/clientreport" className="collapse-item" onClick={() => handleListClick("/clientreport")}>
                    <span>Client Report</span>
                  </Link>

                  <Link to="/vendorreport" className="collapse-item" onClick={() => handleListClick("/vendorreport")}>
                    <span>Vendor Report</span>
                  </Link>

                  <Link to="/salesreport" className="collapse-item" onClick={() => handleListClick("/salesreport")}>
                    <span>Sales Report</span>
                  </Link>

                  <Link to="/purchasereport" className="collapse-item" onClick={() => handleListClick("/purchasereport")}>
                    <span>Purchase Report</span>
                  </Link>

                  <Link to="/inwardreport" className="collapse-item" onClick={() => handleListClick("/inwardreport")}>
                    <span>Inward Report</span>
                  </Link>

                  <Link to="/outwardreport" className="collapse-item" onClick={() => handleListClick("/outwardreport")}>
                    <span>Outward Report</span>
                  </Link>

                  <Link to="/productsreport" className="collapse-item" onClick={() => handleListClick("/productsreport")}>
                    <span>Product Report</span>
                  </Link>

                  <Link to="/servicesreport" className="collapse-item" onClick={() => handleListClick("/servicesreport")}>
                    <span>Service Report</span>
                  </Link>

                </div>
              </div>
            </li>
            <hr className="sidebar-divider d-none d-md-block" />
          </ul>
        </div>
        <div className="footer p-1 d-flex align-items-center justify-content-center flex-column">
          <div className='sidebar-footer'>
            <img
              src={footerLogo}
              className="img-logo"
            />
          </div>
          <p style={{ fontSize: "10px" }} className="text-center m-0 p-0 text-white">Version 1.0 &copy; Developed by Prospect Digital</p>
        </div>
        <button style={{ border: "2px solid black", position: "absolute", left: "30px", top: "10px", zIndex: "999" }} id="sidebarToggleTop" className="btn btn-link d-md-none" onClick={changeStyle}>
          <i className="fa fa-bars"></i>
        </button>
      </body>
      {isEmployeeModalOpen && <AddEmployeeTable onClose={handleCloseEmployeeModal} onUpdate={handleUpdate} />}
      {isVendorModalOpen && <AddVendor onClose={handleCloseVendorModal} onUpdate={handleUpdate} />}
      {isAddInquiryModalOpen && <AddInquiry onClose={handleCloseAddInquiryModal} onUpdate={handleUpdate} />}
      {isAddPurchaseModalOpen && <AddPurchase onClose={handleClosePurchaseModal} onUpdate={handleUpdate} />}
      {isAddClientModalOpen && <AddClientModal onClose={handleCloseClientModal} onUpdate={handleUpdate} />}
      {isAddOfficeModalOpen && <AddOfficeForm onClose={handleCloseOfficeModal} onUpdate={handleUpdate} />}
      {isAddServicesProductsModalOpen && <AddServiceProductModal onClose={handleCloseServicesProductsModal} onUpdate={handleUpdate} />}
      {isAddInwardModalopen && <AddInward onClose={handleCloseInwardModal} onUpdate={handleUpdate} />}
      {isAddSalesModalOpen && <AddSales onClose={handleCloseSalesModal} onUpdate={handleUpdate} />}
      {isAddCompanyModalOpen && <AddCompany onClose={handleCloseCompanyModal} onUpdate={handleUpdate} />}
      {isAddCourierModalOpen && <AddCourier onClose={handleCloseCourierModal} onUpdate={handleUpdate} />}
      {isAddOutwardModalOpen && <AddOutward onClose={handleCloseOutWardModal} onUpdate={handleUpdate} />}

    </div>
  )
}

export default Sidebar;



