import React, { useState, useEffect } from "react";
import axios from "axios";
import EditEmployeeModal from "./EditEmployeeModal";
import { ToastContainer, toast } from 'react-toastify';
import myImage from '../../images/brand_images/default_employee.jpg';
import 'react-toastify/dist/ReactToastify.css';

const EmployeeDetails = ({ employee, onClose }) => {
    const [bankdetailsOutHistory, setbankdetailsOutHistory] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false); // State to manage opening/closing edit modal
    const [siteDetails, setSiteDetails] = useState(null);
    const [activeInactiveHistory, setActiveInactiveHistory] = useState(null);
    const [activeInactivelastOccurence, setActiveInactivelastOccurence] = useState(null);
    // pagination 
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);



    const fetchbankdetailsOutHistory = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_LOCAL_URL}/api/asset/history/employee/${employee.id}`
            );
            setbankdetailsOutHistory(response.data);
        } catch (error) {
            console.error("Error fetching check-in/out history:", error);
        }
    };

    const fetchSiteDetails = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_LOCAL_URL}/api/sites/${employee.id}`
            );
            setSiteDetails(response.data);
        } catch (error) {
            console.error("Error fetching site details:", error);
        }
    };

    const fetchActiveInactiveDetails = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_LOCAL_URL}/active_inactive_employee/${employee.id}`
            );
            // Assuming the response contains the required data
            // Adjust this based on the actual structure of your response
            setActiveInactiveHistory(response.data);
        } catch (error) {
            console.error("Error fetching active/inactive details:", error);
        }
    };

    const fetchActiveInactiveLastDetails = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_LOCAL_URL}/active_inactive_employee_last/${employee.id}`
            );
            // Assuming the response contains the required data
            // Adjust this based on the actual structure of your response
            setActiveInactivelastOccurence(response.data);
        } catch (error) {
            console.error("Error fetching active/inactive details:", error);
        }
    };
    useEffect(() => {
        fetchActiveInactiveLastDetails();
        fetchActiveInactiveDetails();
        fetchbankdetailsOutHistory();
        fetchSiteDetails();
    }, [employee]);

    console.log(siteDetails);
    // Function to format the date
    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };


    // Function to handle opening edit modal
    const handleEditEmployee = () => {
        setIsEditModalOpen(true);
    };
    const handleUpdateEmployees = () => {
        toast.success('Data uploaded successfully');
        fetchActiveInactiveLastDetails();
        fetchActiveInactiveDetails();
        fetchbankdetailsOutHistory();
        fetchSiteDetails();
    };


    // pagination logic
    // Logic to get current items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItemsforbankdetailscheckouthistory = bankdetailsOutHistory ? bankdetailsOutHistory.slice(indexOfFirstItem, indexOfLastItem) : [];
    const currentItemsforemployeehistory = activeInactiveHistory ? activeInactiveHistory.slice(indexOfFirstItem, indexOfLastItem) : [];

    const paginate = (pageNumber) => setCurrentPage(pageNumber);


    return (
        <div>
            <div className="card-body p-4 bg-white rounded shadow-sm">
                <div className="row">

                    <div className="col-md-9 d-flex  justify-content-between px-3">
                        <div>
                            <h2 style={{ color: "#00509d" }} className="title-detail fw-bolder font-bold m-0">
                                {employee.ename}
                            </h2>
                            <h6 style={{ color: "#00509d" }} className="title-detail fw-bolder font-bold m-0">
                                Employee Code: {employee.ecode}
                            </h6>
                            <small>Created By - {employee.username || "N/A"}</small>
                        </div>
                        <div>
                            <p className="m-0">
                                <i class="fa fa-envelope" aria-hidden="true"></i> <span> Email: {employee.eemail || "N/A"}</span>
                            </p>
                            <p className="m-0">
                                <i class="fa fa-phone"></i> <span> Phone: {employee.mobileNumber || "N/A"}</span>
                            </p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="p-2 barcode-inner d-flex flex-column gap-1">
                            <div className="d-flex gap-2 align-items-center justify-content-center">
                                <button style={{ whiteSpace: "nowrap" }} onClick={onClose} className="button_edit">
                                    <i className="fa fa-arrow-left"></i>   Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a
                                    style={{ color: "grey" }}
                                    className="nav-link button-link active show"
                                    id="details-tab"
                                    data-toggle="tab"
                                    href="#details"
                                    role="tab"
                                    aria-controls="details"
                                    aria-selected="true"
                                >
                                    Employee Details
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    style={{ color: "grey" }}
                                    className="nav-link button-link"
                                    id="bank-details"
                                    data-toggle="tab"
                                    href="#bankdetails"
                                    role="tab"
                                    aria-controls="bankdetails"
                                    aria-selected="false"
                                >
                                    Bank Details
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div
                                className="tab-pane fade active show"
                                id="details"
                                role="tabpanel"
                                aria-labelledby="details-tab"
                            >
                                <div className="row">
                                    <div className="col-md-9 d-flex" style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto", overflowX: "hidden" }}>
                                        <table className="table table-bordered table-hover" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td >
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Employee Name:</span> {employee.ename || "-"}</p>
                                                    </td>

                                                    <td >
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Employee Code: </span> {employee.ecode || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Father's Name:</span> {employee.fatherName || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Mother's Name:</span> {employee.motherName || "-"}</p>
                                                    </td>
                                                </tr>
                                               

                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Blood Group:</span> {employee.bloodGroup || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Department:</span>  {employee.department || "-"}</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Mobile Number: </span> {employee.mobileNumber || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Email:</span> {employee.eemail || "-"}</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >PAN Number: </span> {employee.panNo || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Aadhar Card Number: </span> {employee.aadharCardNumber || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Joining Date:</span> {formatDate(employee.joiningDate) || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Current Salary: </span> {employee.currentSalary || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Current Address:</span> {employee.currentAddress || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Current City:</span> {employee.currentCity || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Current State:</span> {employee.currentState || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Current Pincode:</span> {employee.currentPincode || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Permanent Address:</span> {employee.permanentAddress || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Permanent City:</span> {employee.permanentCity || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Permanent State:</span> {employee.permanentState || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Permanent Pincode:</span> {employee.permanentPincode || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Emergency Contact Name:</span> {(employee.emergencyContactName) || "-"} - ({(employee.emergencyContactRelation) || "-"}) </p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Emergency Contact No:</span> {employee.emergencyContactNumber || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Additional Information:</span> {employee.additionalInformation || "-"}</p>
                                                    </td>
                                                    {employee.maritalStatus === "Yes" && (
                                                    
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Spouse Name:</span> {employee.spouseName || "-"}</p>
                                                    </td>
                                                
                                            )}
                                                </tr>
                                               
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* Image Section */}
                                    <div class="col-md-3 pt-2 text-center">
                                         <img
                                            src={employee.picture
                                              ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${employee.picture}`
                                              : myImage}
                                            style={{ width: "90px" }}
                                            className="employee-image"
                                          />
                                    </div>
                                </div>
                            </div>
                            {/* Bank Details Tab Content */}
                            <div className="tab-pane fade" id="bankdetails" role="tabpanel" aria-labelledby="bank-details">
                                <div className="row">
                                    <div className="col-md-9" style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto", overflowX: "hidden" }}>
                                    <table className="table table-bordered table-hover" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Account Name:</span> {employee.accountName  || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Account Relation:</span>  {employee.accountRelation || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Bank Name:</span> {employee.bankName  || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Account Number:</span>  {employee.accountNumber || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >IFSC Code:</span> {employee.ifscCode  || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Branch Address:</span>  {employee.branch || "-"}</p>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    {/* Image Section */}
                                    <div class="col-md-3 pt-2 text-center">
                                         <img
                                            src={employee.picture
                                              ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${employee.picture}`
                                              : myImage}
                                            style={{ width: "90px" }}
                                            className="employee-image"
                                          />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isEditModalOpen && (
                <EditEmployeeModal
                    employee={employee}
                    onUpdate={handleUpdateEmployees}
                    onClose={() => setIsEditModalOpen(false)}
                />
            )}
        </div>
    );
};

export default EmployeeDetails;




















































































