import React, { useState, useEffect } from "react";
import axios from "axios";
import myImage from '../../images/brand_images/default_employee.jpg';
import EditClientModal from "./EditClientModal";
import SalesHistory from "./SalesHistory";
import InwardHistory from "./InwardHistory";
import OutwardHistory from "./OutwardHistory";


const ClientDesc = ({ client, onClose }) => {
    const [inwardHistory, setInwardHistory] = useState([]);
    const [outwardHistory, setOutwardHistory] = useState([]);
    const [salesHistory, setSalesHistory] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedinwardEntry, setSelectedinwardEntry] = useState(null);
    const [selectedOutwardEntry, setSelectedOutwardEntry] = useState(null);
    const [selectedSalesEntry, setSelectedSalesEntry] = useState(null);
    const [salesModalOpen, setsalesModalOpen] = useState(false);
    const [inwardModalOpen, setinwardModalOpen] = useState(false);
    const [outwardModalOpen, setoutwardModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    // Action Modal Open 
    // salesHistory
    const [salesHistoryModal, setSalesHistoryModal] = useState(false);
    const [salesHistoryDetails, setSalesHistoryDetails] = useState(null);
    // InwardHistory
    const [inwardHistoryModal, setInwardHistoryModal] = useState(false);
    const [inwardHistoryDetails, setInwardHistoryDetails] = useState(null);
    // OutwardHistory
    const [outwardHistoryModal, setOutwardHistoryModal] = useState(false);
    const [outwardHistoryDetails, setOutwardHistoryDetails] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const salesResponse = await axios.get(
                     `${process.env.REACT_APP_LOCAL_URL}/api/sales/${client.id}`
                );
                setSalesHistory(salesResponse.data);

                const inwardResponse = await axios.get(
                    `${process.env.REACT_APP_LOCAL_URL}/api/inward/${client.id}`
                );
                setInwardHistory(inwardResponse.data);

                const outwardResponse = await axios.get(
                    `${process.env.REACT_APP_LOCAL_URL}/api/outward/${client.id}`
                );
                setOutwardHistory(outwardResponse.data);
            } catch (error) {
                console.error("Error fetching history:", error);
            }
        };

        fetchData();
    }, [client.id]);

    const handleViewoutwardClick = (entry) => {
        setSelectedOutwardEntry(entry);
        setoutwardModalOpen(true);
    };
    const handleViewClick = (entry) => {
        setSelectedinwardEntry(entry);
        setinwardModalOpen(true);
    };
    const handleViewSalesClick = (entry) => {
        setSelectedSalesEntry(entry)
        setsalesModalOpen(true);
    };

    const closeModal = () => {
        setsalesModalOpen(false);
        setinwardModalOpen(false);
        setoutwardModalOpen(false);
        setSelectedinwardEntry(null);
        setSelectedOutwardEntry(null);
        setSelectedSalesEntry(null);
    };

    const handleEditClient = () => {
        setIsEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
    };


    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItemsInward = inwardHistory.slice(indexOfFirstItem, indexOfLastItem);
    const currentItemsOutward = outwardHistory.slice(indexOfFirstItem, indexOfLastItem);
    const currentItemsSales = salesHistory.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Calculate total sales amount
    const totalSalesAmount = salesHistory.reduce((total, sale) => total + sale.totalSalesAmount, 0);


    // Download Button 
    const handleDownload = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/generate-pdf/client/${client.id}`, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${client.clientName}-details.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error downloading PDF:", error);
        }
    };

    // Add Sales History Modal 
    const handleSalesHistory = (entry) => {
        setSalesHistoryDetails(entry)
        setSalesHistoryModal(true);
    };
    const handleCloseSalesHistory = () => {
        setSalesHistoryModal(false);
    };

    // Add Inward History Modal 
    const handleInwardHistory = (entry) => {
        setInwardHistoryDetails(entry)
        setInwardHistoryModal(true);
    };  
    const handleCloseInwardHistory = () => {
        setInwardHistoryModal(false);
    }

    // Add Outward History Modal 
    const handleOutwardHistory = (entry) => {
        setOutwardHistoryDetails(entry)
        setOutwardHistoryModal(true);
    };  
    const handleCloseOutwardHistory = () => {
        setOutwardHistoryModal(false);
    }

    return (
        <div>
            <div className="card-body p-4 bg-white rounded shadow-sm">
                <div className="row">

                    <div className="col-md-9 d-flex  justify-content-between px-3">
                        <div>
                            <h2 style={{ color: "#00509d" }} className="title-detail fw-bolder font-bold m-0">
                                {client.clientName}
                            </h2>
                            <h6 style={{ color: "#00509d" }} className="title-detail fw-bolder font-bold m-0">
                                Client Code: {client.clientCode}
                            </h6>
                            <small>Created By - {client.username || "N/A"}</small>
                        </div>
                        <div>
                            <p className="m-0">
                                <i class="fa fa-envelope" aria-hidden="true"></i> <span> Email: {client.emailId1 || "N/A"}</span>
                            </p>
                            <p className="m-0">
                                <i class="fa fa-phone"></i> <span> Phone: {client.mobileNo1 || "N/A"}</span>
                            </p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="p-2 barcode-inner d-flex flex-column gap-1">
                            <div className="d-flex gap-2 align-items-center justify-content-center">
                                <button style={{ whiteSpace: "nowrap" }} onClick={onClose} className="button_edit">
                                    <i className="fa fa-arrow-left"></i>   Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a
                                    style={{ color: "grey" }}
                                    className="nav-link button-link active show"
                                    id="details-tab"
                                    data-toggle="tab"
                                    href="#details"
                                    role="tab"
                                    aria-controls="details"
                                    aria-selected="true"
                                >
                                    Client Details
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    style={{ color: "grey" }}
                                    className="nav-link button-link"
                                    id="sales-tab"
                                    data-toggle="tab"
                                    href="#sales"
                                    role="tab"
                                    aria-controls="sales"
                                    aria-selected="false"
                                >
                                    Sales
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    style={{ color: "grey" }}
                                    className="nav-link button-link"
                                    id="inward-tab"
                                    data-toggle="tab"
                                    href="#inward"
                                    role="tab"
                                    aria-controls="inward"
                                    aria-selected="false"
                                >
                                    Inward
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    style={{ color: "grey" }}
                                    className="nav-link button-link"
                                    id="outward-tab"
                                    data-toggle="tab"
                                    href="#outward"
                                    role="tab"
                                    aria-controls="outward"
                                    aria-selected="false"
                                >
                                    Outward
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div
                                className="tab-pane fade active show"
                                id="details"
                                role="tabpanel"
                                aria-labelledby="details-tab"
                            >
                                <div className="row">
                                    <div className="col-md-9 d-flex" style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto", overflowX: "hidden" }}>
                                        <table className="table table-bordered table-hover" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td >
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Client Name:</span> {client.clientName || "-"}</p>
                                                    </td>

                                                    <td >
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Client Code:</span> {client.clientCode || "-"}</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >GST No:</span> {client.gstNo || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >PAN No:</span>  {client.panNo || "-"}</p>
                                                    </td>

                                                </tr>

                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Mobile No1: </span> {client.mobileNo1 || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Mobile No2: </span> {client.mobileNo2 || "-"}</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Mobile No3: </span> {client.mobileNo3 || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Email Id 1:</span> {client.emailId1 || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Email Id 2:</span> {client.emailId2 || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Contact Person:</span> {client.contactPersonName || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Address 1:</span> {client.address1 || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >City 1:</span> {client.clientCity1 || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >State 1:</span> {client.clientState1 || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Pincode:</span> {client.clientPincode1 || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Address 2:</span> {client.address2 || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >City 2:</span> {client.clientCity2 || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >State 2:</span> {client.clientState2 || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Pincode 2:</span> {client.clientPincode2 || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Remark:</span> {client.remark || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Bank Name:</span> {client.bankName || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Account No:</span> {client.accountNo || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >IFSC Code:</span> {client.ifscCode || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Branch:</span> {client.branch || "-"}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* Image Section */}
                                    <div class="col-md-3 pt-2 text-center">
                                        <img
                                            src={client.upload
                                                ? `${process.env.REACT_APP_LOCAL_URL}/uploads/clients/${client.upload}`
                                                : myImage}
                                            style={{ width: "90px" }}
                                            className="employee-image"
                                        />
                                        <div className=" p-5">
                                            Total Sales Amount :- {totalSalesAmount}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Sales history */}
                            <div className="tab-pane fade" id="sales" role="tabpanel" aria-labelledby="sales-tab">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Client Name</th>
                                                    <th>company Name</th>
                                                    <th>Sales Executive Name</th>
                                                    <th>Total Amount</th>
                                                    <th>sales Date</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItemsSales.map((entry) => (
                                                    <tr key={entry.id}>
                                                        <td>{entry.clientName}</td>
                                                        <td>{entry.companyName}</td>
                                                        <td>{entry.salesExecutiveName}</td>
                                                        <td>{entry.totalSalesAmount}</td>
                                                        <td>{formatDate(entry.salesDate)}</td>
                                                        <td><button className="btn btn-outline-info" onClick={() => handleSalesHistory(entry)}>View</button></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
                                            </li>
                                            {Array.from({ length: Math.ceil(salesHistory.length / itemsPerPage) || 1 }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                                    <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(salesHistory.length / itemsPerPage) ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* Inward history */}
                            <div className="tab-pane fade" id="inward" role="tabpanel" aria-labelledby="inward-tab">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Client Name</th>
                                                    <th>Consignment Date</th>
                                                    <th>Courier Company</th>
                                                    <th>Received By </th>
                                                    <th>Received Date</th>
                                                    <th>Consignment Subject</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItemsInward.map((entry) => (
                                                    <tr key={entry.id}>
                                                        <td>{entry.entityName}</td>
                                                        <td>{formatDate(entry.consignmentdate)}</td>
                                                        <td>{entry.courierCompany}</td>
                                                        <td>{entry.ename}</td>
                                                        <td>{formatDate(entry.receiptDate)}</td>
                                                        <td>{entry.consignmentType}</td>
                                                        <td><button className="btn btn-outline-info" onClick={() => handleInwardHistory(entry)}>View</button></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
                                            </li>
                                            {Array.from({ length: Math.ceil(inwardHistory.length / itemsPerPage) || 1 }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                                    <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(inwardHistory.length / itemsPerPage) ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* Outward History */}
                            <div className="tab-pane fade" id="outward" role="tabpanel" aria-labelledby="outward-tab">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Client Name</th>
                                                    <th>Consignment Date</th>
                                                    <th>Courier Company</th>
                                                    <th>Dispatched By </th>
                                                    <th>Consignment Subject</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItemsOutward.map((entry) => (
                                                    <tr key={entry.id}>
                                                        <td>{entry.entityName}</td>
                                                        <td>{formatDate(entry.consignmentdate)}</td>
                                                        <td>{entry.courierCompany}</td>
                                                        <td>{entry.ename}</td>
                                                        <td>{entry.consignmentType}</td>
                                                        <td><button className="btn btn-outline-info" onClick={() => handleOutwardHistory(entry)}>View</button></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
                                            </li>
                                            {Array.from({ length: Math.ceil(outwardHistory.length / itemsPerPage) || 1 }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                                    <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(outwardHistory.length / itemsPerPage) ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isEditModalOpen && <EditClientModal
                client={client}
                onClose={handleCloseEditModal}
            />} 
            {/* Pass onUpdate function */}
            {/* Sales History */}
            {salesHistoryModal && <SalesHistory
            salesDetails={salesHistoryDetails} onClose={handleCloseSalesHistory} />}
            {/* Inward */}
            {inwardHistoryModal && <InwardHistory
            inwardDetails={inwardHistoryDetails} onClose={handleCloseInwardHistory} />}
            {/* Inward */}
            {outwardHistoryModal && <OutwardHistory
            outwardDetails={outwardHistoryDetails} onClose={handleCloseOutwardHistory} />}
        </div>
    );
};

export default ClientDesc;



































































