import React from 'react'

const EditCourier = () => {
  return (
    <div>
      
    </div>
  )
}

export default EditCourier
