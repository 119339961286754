import React, { useState, useEffect } from 'react';

const InwardHistory = ({inwardDetails, onClose }) => {
    console.log(inwardDetails);
    const handleClose = () => {
        onClose();
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    return (
        <div id="add" className="modal fade show" role="dialog" style={{ display: "block" }}>
            <div style={{ borderRadius: "20px" }} className="modal-dialog modal-lg overflow-hidden">
                <div className="modal-content">
                    <div style={{ backgroundColor: "#00509D", color: "white" }} className="modal-header">
                        <h5 className="modal-title">Inward History</h5>
                        <button type="button" className="button_details " onClick={handleClose}>
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                    <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                    <div className="modalmain">
                        <div>
                            <p><strong>Client Name:</strong> {inwardDetails.entityName}</p>
                            <p><strong>Client Code:</strong> {inwardDetails.code}</p>
                            <p><strong>Address:</strong> {inwardDetails.address}</p>
                            <p><strong>Sate:</strong> {inwardDetails.state}</p>
                            <p><strong>City:</strong> {inwardDetails.city}</p>
                            <p><strong>Pincode:</strong> {inwardDetails.pincode}</p>
                            <p><strong>Courier Company:</strong> {inwardDetails.courierCompany}</p>
                            <p><strong>Consignment No:</strong> {inwardDetails.consignmentNo}</p>
                            <p><strong>Consignment Date:</strong> {formatDate(inwardDetails.consignmentdate)}</p>
                            <p><strong>Ship From:</strong> {inwardDetails.shipfromaddress}</p>
                            <p><strong>Ship To:</strong> {inwardDetails.shipTo}</p>
                            <p><strong>Received Date:</strong> {formatDate(inwardDetails.receiptDate)}</p>
                            <p><strong>Received By:</strong> {inwardDetails.ename}</p>
                            <p><strong>Consignment Type:</strong> {inwardDetails.consignmentType}</p>
                            <p><strong>Remark:</strong> {inwardDetails.remark}</p>
                            <p><strong>Invoice:</strong> {inwardDetails.invoice || "-"}</p>
                        </div>
                        <div style={{ width: "15vw", height: "30vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img style={{ width: "100%", height: "100%", objectFit: "contain" }} src={`${process.env.REACT_APP_LOCAL_URL}/uploads/inward/${inwardDetails.photo}`} alt="inward Upload" />
                        </div>
                        </div>
                    </div>
                    <div style={{ backgroundColor: "#00509D", color: "white" }} className="modal-footer">
                        <button type="button" className="button_details" onClick={handleClose}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InwardHistory;
