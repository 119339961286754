import React, { useState, useEffect } from "react";
import axios from "axios";
import ServiceDesc from "./ServiceDesc";
import EditService from "./EditService";
import DeleteConfirmationModal from "../DeleteConfirmationModal"; // Import the new component
import SearchBar from "../../components/sidebar/SearchBar";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner'; // Spinner
import 'react-toastify/dist/ReactToastify.css';

import AddServiceProductModal from "../Services_Products/AddServiceProductModal";

function Servicelist({ handleLogout, username }) {
    const [isLoading, setIsLoading] = useState(false);
    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [showServiceDetails, setShowServiceDetails] = useState(false);
    const [isAddServiceModalOpen, setIsAddServiceModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editService, setEditService] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [deleteService, setDeleteService] = useState([]); // State to store data of service being deleted
    useEffect(() => {
        fetchServices();
    }, []);

    const fetchServices = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/service_product`);
            const services = response.data.filter(item => item.type === "Service");
            setServices(services);
        } catch (error) {
            console.error('Error fetching Services:', error);
        }finally {
            setIsLoading(false);
        }
    };

    const handleAddService = () => {
        setIsAddServiceModalOpen(true);
    };

    const handleCloseServiceModal = () => {
        setIsAddServiceModalOpen(false);
        setIsEditModalOpen(false);
    };

    const handleServiceDetails = (service) => {
        setSelectedService(service);
        setShowServiceDetails(true);
    };

    const handleEditServiceClick = (service) => {
        setEditService(service);
        setSelectedService(service); // Update selectedService state
        setIsEditModalOpen(true);
    };

    const handleEditServiceClose = () => {
        setSelectedService(null);
    };

    const handleBackToTable = () => {
        setSelectedService(null);
        setShowServiceDetails(false);
    };


    const handleUpdateService = async (updatedService) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/services/${updatedService.id}`, updatedService);
            console.log("Service updated:", response.data);
            const updatedServices = services.map(service => (service.id === updatedService.id ? response.data : service));
            setServices(updatedServices);
        } catch (error) {
            console.error("Error updating service:", error);
        }
    };

    const handleUpdateServices = () => {
        toast.success("successfully uploaded");
        fetchServices();
    };

    const handleDelete = async (service) => {
        try {
            await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/services/${service.id}`);
            setDeleteService(prevServices => prevServices.filter(item => item.id !== service.id));
            toast.success("service deleted successfully");
            fetchServices();
        } catch (error) {
            console.error("Error deleting sales:", error);
        }
    };


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = services.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className='d-flex w-100 h-100 bg-white'>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    {!showServiceDetails && (
                        <div className="row">
                            <div className="col-xl-12">
                                <div style={{ borderRadius: "20px", border: "1px solid #003459" }} className='overflow-hidden'>
                                    <div style={{background: "rgb(33,131,128)", background: "#00509d " }} className="row no-gutters align-items-center p-3">
                                        <div className="col">
                                            <div className="text-xs font-weight-bold text-white text-uppercase d-flex align-items-center justify-content-between" style={{ fontSize: '1.5rem' }}>
                                                <div className="nunito text-white"> Service List</div>
                                                <button onClick={handleAddService} className="button_details"><i className="fa fa-plus"></i>  Add New Service</button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='m-0 p-0' />
                                    <div className=''>
                                        <div className="card-body">
                                            <div className="" style={{ maxHeight: "610px", overflowY: "auto" }}>
                                                {isLoading ? (
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <ThreeDots color="#00509d" height={80} width={80} />
                                                    </div>
                                                ) : (
                                                    <table className="table table-bordered" style={{ width: "100%" }}>
                                                        <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                            <tr>
                                                                <th>Service Name</th>
                                                                <th>Service Code</th>
                                                                <th>Remark</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <style>
                                                                {`.hyperlink:hover {color: blue;}`}
                                                            </style>
                                                            {currentItems.length === 0 ? (
                                                                <tr>
                                                                    <td colSpan="12" className="text-center">No Inquiry Entries.</td>
                                                                </tr>
                                                            ) : (
                                                                currentItems.map((service, index) => (
                                                                    <tr key={index}>
                                                                        <td>{service.name}</td>
                                                                        <td>{service.code}</td>
                                                                        <td>{service.remark || "-"}</td>
                                                                        <td>
                                                                            <div className="d-flex align-items-center justify-content-start gap-3 z-99">
                                                                                <div className="btn-group">
                                                                                    <button
                                                                                        className="button_action"
                                                                                        type="button"
                                                                                        data-toggle="dropdown"
                                                                                        aria-haspopup="true"
                                                                                        aria-expanded="false"
                                                                                    >
                                                                                        <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                                                                    </button>
                                                                                    <div className="dropdown-menu actionmenu">

                                                                                        {/* <a className="dropdown-item" href="#" onClick={() => handleInwardDetails(inward)}>
                                                                                <i className="fa fa-file"></i> Detail
                                                                            </a>
                                                                            <a className="dropdown-item" href="#" onClick={() => handleEditInwardClick(inward)}>
                                                                                <i className="fas fa-edit"></i> Edit
                                                                            </a> */}
                                                                                        <a className="dropdown-item" href="#" onClick={() => handleDelete(service)}>
                                                                                            <i className="fa fa-trash"></i> Delete
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            )}
                                                        </tbody>
                                                    </table>
                                                )}
                                            </div>
                                            <ul className="pagination mt-2">
                                                <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                                </li>
                                                {Array.from({ length: Math.ceil(services.length / itemsPerPage) }, (_, i) => (
                                                    <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                        <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                                                    </li>
                                                ))}
                                                <li className={`page-item ${currentPage === Math.ceil(services.length / itemsPerPage) && 'disabled'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {showServiceDetails && selectedService && (
                                        <ServiceDesc
                                            service={selectedService}
                                            onClose={handleBackToTable}
                                        />
                                    )}
                                    {selectedService && !showServiceDetails && (
                                        <EditService service={selectedService} onClose={handleEditServiceClose} onUpdate={handleUpdateService} />
                                    )}
                                    {isAddServiceModalOpen && <AddServiceProductModal onClose={handleCloseServiceModal} onUpdateServices={handleUpdateServices} />}

                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Servicelist;


























