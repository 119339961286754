import React, { useState, useEffect } from "react";
import imageCompression from 'browser-image-compression';
import axios from "axios";

const AddEmployeeTable = ({ onClose, onUpdate }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        employeeName: "",
        employeeCode: "",
        fatherName: "",
        motherName: "",
        maritalStatus: "", // New field for marital status
        spouseName: "", // New field for spouse name
        mobileNumber: "",
        emailId: "",
        dateOfBirth: "",
        bloodGroup: "",
        panNo: "",
        aadharCardNumber: "",
        currentAddress: "",
        currentState: "",
        currentCity: "",
        currentPincode: "",
        permanentAddress: "",
        permanentState: "",
        permanentCity: "",
        permanentPincode: "",
        joiningDate: "",
        currentSalary: "", // Corrected variable name
        designation: "",
        department: "",
        accountNumber: "",
        accountRelation: "",
        accountName: "",
        bankName: "", // Removed bankaccount and bankrelation
        branch: "",
        ifscCode: "",
        emergencyContactName: "", // Updated variable name
        emergencyContactNumber: "", // Updated variable name
        emergencyContactRelation: "", // Updated variable name
        upload1: null,
        upload2: null,
        additionalInformation: "",
        status: 'active', // Add status field with default value 'active'
        username: localStorage.getItem('username'),
    });

    const apiUrl = process.env.REACT_APP_LOCAL_URL;
    const [errors, setErrors] = useState({});
    const [states, setStates] = useState([]);

    useEffect(() => {
        fetchStates();
    }, []);

    const fetchStates = async () => {
        try {
            const response = await axios.get(`${apiUrl}/states`);
            setStates(response.data);
        } catch (error) {
            console.error("Error fetching states:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        try {
            const options = {
                maxSizeMB: 0.2,
                maxWidthOrHeight: 800,
                useWebWorker: true,
            };

            const compressedFile = await imageCompression(file, options);
            const fileWithMetaData = new File([compressedFile], file.name, { type: compressedFile.type });

            setFormData(prevState => ({
                ...prevState,
                [e.target.name]: fileWithMetaData, // Corrected target name
            }));
        } catch (error) {
            console.error('Image compression error:', error);
        }
    };

    const validate = () => {
        let formErrors = {};
        // Validation for employee information
        if (!formData.employeeName) formErrors.employeeName = 'Employee Name is required';
        if (!formData.employeeCode) formErrors.employeeCode = 'Employee Code is required';
        if (!formData.fatherName) formErrors.fatherName = 'Father Name is required';
        if (!formData.motherName) formErrors.motherName = 'Mother Name is required';
        if (!formData.maritalStatus) formErrors.maritalStatus = 'Marital Status is required';
        if (!formData.mobileNumber) formErrors.mobileNumber = 'Mobile Number is required';
        if (!formData.emailId) formErrors.emailId = 'Email Id is required';
        if (!formData.dateOfBirth) formErrors.dateOfBirth = 'Date of Birth is required';
        if (!formData.bloodGroup) formErrors.bloodGroup = 'Blood Group is required';
        if (!formData.panNo) formErrors.panNo = 'PAN Number is required';
        if (!formData.aadharCardNumber) formErrors.aadharCardNumber = 'Aadhar Card Number is required';
        if (!formData.currentAddress) formErrors.currentAddress = 'Current Address is required';
        if (!formData.currentState) formErrors.currentState = 'Current State is required';
        if (!formData.currentCity) formErrors.currentCity = 'Current City is required';
        if (!formData.currentPincode) formErrors.currentPincode = 'Current Pincode is required';
        if (!formData.permanentAddress) formErrors.permanentAddress = 'Permanent Address is required';
        if (!formData.permanentState) formErrors.permanentState = 'Permanent State is required';
        if (!formData.permanentCity) formErrors.permanentCity = 'Permanent City is required';
        if (!formData.permanentPincode) formErrors.permanentPincode = 'Permanent Pincode is required';
        if (!formData.joiningDate) formErrors.joiningDate = 'Joining Date is required';
        if (!formData.currentSalary) formErrors.currentSalary = 'Current Salary is required';
        if (!formData.designation) formErrors.designation = 'Designation is required';
        if (!formData.department) formErrors.department = 'Department is required';
        if (!formData.accountNumber) formErrors.accountNumber = 'Account Number is required';
        if (!formData.accountRelation) formErrors.accountRelation = 'Account Relation is required';
        if (!formData.accountName) formErrors.accountName = 'Account Name is required';
        if (!formData.bankName) formErrors.bankName = 'Bank Name is required';
        if (!formData.branch) formErrors.branch = 'Branch is required';
        if (!formData.ifscCode) formErrors.ifscCode = 'IFSC Code is required';
        if (!formData.emergencyContactName) formErrors.emergencyContactName = 'Emergency Contact Name is required';
        if (!formData.emergencyContactNumber) formErrors.emergencyContactNumber = 'Emergency Contact Number is required';
        if (!formData.emergencyContactRelation) formErrors.emergencyContactRelation = 'Emergency Contact Relation is required';
        if (!formData.upload1) formErrors.upload1 = 'Please upload image is required';

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();

        if (!validate()) {
            return;
        }

        const data = new FormData();
        Object.keys(formData).forEach(key => {
            data.append(key, formData[key]);
        });

        try {
            const response = await axios.post(`${apiUrl}/employeeData`, data);
            console.log("Data uploaded successfully:", response.data);
            onUpdate();
            setTimeout(() => {
                onClose();
                window.location.reload(); // Reload the page after submission
            }, 1000);
        } catch (error) {
            console.error("Error uploading data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div className="modal fade show" role="dialog" style={{ display: "block" }}>
            <div style={{ borderRadius: "20px" }} className="modal-dialog modal-lg overflow-hidden">
                <div className="modal-content">
                    <form id="formAddEmployee" autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <div style={{ backgroundColor: "#00509D", color: "white" }} className="modal-header">
                            <h5 className="modal-title">Add Employee</h5>
                            <button type="button" className="button_details " onClick={handleClose}>
                                <i className="fa-solid fa-xmark"></i>
                            </button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                            <div className="form-row">
                                {/* Input fields remain the same */}
                                {/* Employee Name */}
                                <div className="form-group col-md-6">
                                    <label>Employee Name <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="employeeName"
                                        type="text"
                                        className={`form-control ${errors.employeeName ? 'is-invalid' : ''}`}
                                        value={formData.employeeName}
                                        onChange={handleChange}
                                        placeholder="Employee Name"
                                    />
                                    {errors.employeeName && <small className="text-danger">{errors.employeeName}</small>}
                                </div>

                                {/* Employee Code */}
                                <div className="form-group col-md-6">
                                    <label>Employee Code <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="employeeCode"
                                        type="text"
                                        className={`form-control ${errors.employeeCode ? 'is-invalid' : ''}`}
                                        value={formData.employeeCode}
                                        onChange={handleChange}
                                        placeholder="Employee Code"
                                    />
                                    {errors.employeeCode && <small className="text-danger">{errors.employeeCode}</small>}
                                </div>

                                {/* Father's Name */}
                                <div className="form-group col-md-6">
                                    <label>Father's Name <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="fatherName"
                                        type="text"
                                        className={`form-control ${errors.fatherName ? 'is-invalid' : ''}`}
                                        value={formData.fatherName}
                                        onChange={handleChange}
                                        placeholder="Father's Name"
                                    />
                                    {errors.fatherName && <small className="text-danger">{errors.fatherName}</small>}
                                </div>

                                {/* Mother's Name */}
                                <div className="form-group col-md-6">
                                    <label>Mother's Name <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="motherName"
                                        type="text"
                                        className={`form-control ${errors.motherName ? 'is-invalid' : ''}`}
                                        value={formData.motherName}
                                        onChange={handleChange}
                                        placeholder="Mother's Name"
                                    />
                                    {errors.motherName && <small className="text-danger">{errors.motherName}</small>}
                                </div>

                                {/* Marital Status */}
                                <div className="form-group col-md-12">
                                    <label>Marital Status <span style={{ color: "red" }}>*</span></label>
                                    <select
                                        name="maritalStatus"
                                        className={`form-control ${errors.maritalStatus ? 'is-invalid' : ''}`}
                                        value={formData.maritalStatus}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                    {errors.maritalStatus && <small className="text-danger">{errors.maritalStatus}</small>}
                                </div>
                                {/* Spouse Name */}
                                {formData.maritalStatus === "Yes" && (
                                    <div className="form-group col-md-12">
                                        <label>Spouse Name <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="spouseName"
                                            type="text"
                                            className="form-control"
                                            value={formData.spouseName}
                                            onChange={handleChange}
                                            placeholder="Spouse Name"
                                        />
                                    </div>
                                )}


                                {/* Mobile Number */}
                                <div className="form-group col-md-4">
                                    <label>Mobile Number <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="mobileNumber"
                                        type="tel"
                                        className={`form-control ${errors.mobileNumber ? 'is-invalid' : ''}`}
                                        value={formData.mobileNumber}
                                        onChange={handleChange}
                                        placeholder="Mobile Number"
                                    />
                                    {errors.mobileNumber && <small className="text-danger">{errors.mobileNumber}</small>}
                                </div>

                                {/* Email ID */}
                                <div className="form-group col-md-4">
                                    <label>Email ID <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="emailId"
                                        type="email"
                                        className={`form-control ${errors.emailId ? 'is-invalid' : ''}`}
                                        value={formData.emailId}
                                        onChange={handleChange}
                                        placeholder="Email ID"
                                    />
                                    {errors.emailId && <small className="text-danger">{errors.emailId}</small>}
                                </div>

                                {/* Date of Birth */}
                                <div className="form-group col-md-4">
                                    <label>Date of Birth <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="dateOfBirth"
                                        type="date"
                                        className={`form-control ${errors.dateOfBirth ? 'is-invalid' : ''}`}
                                        value={formData.dateOfBirth}
                                        onChange={handleChange}
                                    />
                                    {errors.dateOfBirth && <small className="text-danger">{errors.dateOfBirth}</small>}
                                </div>

                                {/* Blood Group */}
                                <div className="form-group col-md-4">
                                    <label>Blood Group</label>
                                    <select
                                        name="bloodGroup"
                                        className="form-control"
                                        value={formData.bloodGroup}
                                        onChange={handleChange}
                                    >
                                        <option value="" disabled hidden>Select Blood Group</option>
                                        <option value="A+">A+</option>
                                        <option value="A-">A-</option>
                                        <option value="B+">B+</option>
                                        <option value="B-">B-</option>
                                        <option value="AB+">AB+</option>
                                        <option value="AB-">AB-</option>
                                        <option value="O+">O+</option>
                                        <option value="O-">O-</option>
                                        <option value="DontKnow">Don't Know</option>
                                    </select>
                                </div>


                                {/* PAN No */}
                                <div className="form-group col-md-4">
                                    <label>PAN No <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="panNo"
                                        type="text"
                                        className={`form-control ${errors.panNo ? 'is-invalid' : ''}`}
                                        value={formData.panNo}
                                        onChange={handleChange}
                                        placeholder="PAN No"
                                    />
                                    {errors.panNo && <small className="text-danger">{errors.panNo}</small>}
                                </div>

                                {/* Aadhar Card Number */}
                                <div className="form-group col-md-4">
                                    <label>Aadhar Card Number <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="aadharCardNumber"
                                        type="text"
                                        className={`form-control ${errors.aadharCardNumber ? 'is-invalid' : ''}`}
                                        value={formData.aadharCardNumber}
                                        onChange={handleChange}
                                        placeholder="Aadhar Card Number"
                                    />
                                    {errors.aadharCardNumber && <small className="text-danger">{errors.aadharCardNumber}</small>}
                                </div>
                                <hr />

                                {/* Current Address */}

                                <div className="form-group col-md-12">
                                    <label>Current Address <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="currentAddress"
                                        type="text"
                                        className={`form-control ${errors.currentAddress ? 'is-invalid' : ''}`}
                                        value={formData.currentAddress}
                                        onChange={handleChange}
                                        placeholder="Current Address"
                                    />
                                    {errors.currentAddress && <small className="text-danger">{errors.currentAddress}</small>}
                                </div>

                                {/* Current State */}
                                <div className="form-group col-md-4">
                                    <label>Current State <span style={{ color: "red" }}>*</span></label>
                                    <select
                                        name="currentState"
                                        className={`form-control ${errors.currentState ? 'is-invalid' : ''}`}
                                        value={formData.currentState}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select State</option>
                                        {states.map(state => (
                                            <option key={state.id} value={state.statename}>{state.statename}</option>
                                        ))}
                                    </select>
                                    {errors.currentState && <small className="text-danger">{errors.currentState}</small>}
                                </div>

                                {/* Current City */}
                                <div className="form-group col-md-4">
                                    <label>Current City <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="currentCity"
                                        type="text"
                                        className={`form-control ${errors.currentCity ? 'is-invalid' : ''}`}
                                        value={formData.currentCity}
                                        onChange={handleChange}
                                        placeholder="Current City"
                                    />
                                    {errors.currentCity && <small className="text-danger">{errors.currentCity}</small>}
                                </div>

                                {/* Current Pincode */}
                                <div className="form-group col-md-4">
                                    <label>Current Pincode <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="currentPincode"
                                        type="text"
                                        className={`form-control ${errors.currentPincode ? 'is-invalid' : ''}`}
                                        value={formData.currentPincode}
                                        onChange={handleChange}
                                        placeholder="Current Pincode" />
                                    {errors.currentPincode && <small className="text-danger">{errors.currentPincode}</small>}
                                </div>
                                <hr />

                                {/* Permanent Address */}

                                <div className="form-group col-md-12">
                                    <label>Permanent Address  <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="permanentAddress"
                                        type="text"
                                        className={`form-control ${errors.permanentAddress ? 'is-invalid' : ''}`}
                                        value={formData.permanentAddress}
                                        onChange={handleChange}
                                        placeholder="Permanent Address"
                                    />
                                    {errors.permanentAddress && <small className="text-danger">{errors.permanentAddress}</small>}
                                </div>

                                {/* Permanent State */}
                                <div className="form-group col-md-4">
                                    <label>Permanent State  <span style={{ color: "red" }}>*</span></label>
                                    <select
                                        name="permanentState"
                                        className={`form-control ${errors.permanentState ? 'is-invalid' : ''}`}
                                        value={formData.permanentState}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select State</option>
                                        {states.map(state => (
                                            <option key={state.id} value={state.statename}>{state.statename}</option>
                                        ))}
                                    </select>
                                    {errors.permanentState && <small className="text-danger">{errors.permanentState}</small>}
                                </div>

                                {/* Permanent City */}
                                <div className="form-group col-md-4">
                                    <label>Permanent City  <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="permanentCity"
                                        type="text"
                                        className={`form-control ${errors.permanentCity ? 'is-invalid' : ''}`}
                                        value={formData.permanentCity}
                                        onChange={handleChange}
                                        placeholder="Permanent City"
                                    />
                                    {errors.permanentCity && <small className="text-danger">{errors.permanentCity}</small>}
                                </div>

                                {/* Permanent Pincode */}
                                <div className="form-group col-md-4">
                                    <label>Permanent Pincode  <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="permanentPincode"
                                        type="text"
                                        className={`form-control ${errors.permanentPincode ? 'is-invalid' : ''}`}
                                        value={formData.permanentPincode}
                                        onChange={handleChange}
                                        placeholder="Permanent Pincode"
                                    />
                                    {errors.permanentPincode && <small className="text-danger">{errors.permanentPincode}</small>}
                                </div>

                                {/* Joining Date */}
                                <label htmlFor="">Joining Details :-   <span style={{ color: "red" }}>*</span></label>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>Joining Date  <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="joiningDate"
                                            type="date"
                                            className={`form-control ${errors.joiningDate ? 'is-invalid' : ''}`}
                                            value={formData.joiningDate}
                                            onChange={handleChange}
                                        />
                                        {errors.joiningDate && <small className="text-danger">{errors.joiningDate}</small>}
                                    </div>

                                    {/* Current Salary */}
                                    <div className="form-group col-md-6">
                                        <label>Current Salary  <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="currentSalary"
                                            type="number"
                                            className={`form-control ${errors.currentSalary ? 'is-invalid' : ''}`}
                                            value={formData.currentSalary}
                                            onChange={handleChange}
                                            placeholder="Current Salary"
                                        />
                                        {errors.currentSalary && <small className="text-danger">{errors.currentSalary}</small>}
                                    </div>

                                    {/* Designation */}
                                    <div className="form-group col-md-6">
                                        <label>Designation  <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="designation"
                                            type="text"
                                            className={`form-control ${errors.designation ? 'is-invalid' : ''}`}
                                            value={formData.designation}
                                            onChange={handleChange}
                                            placeholder="Designation"
                                        />
                                        {errors.designation && <small className="text-danger">{errors.designation}</small>}
                                    </div>

                                    {/* Department */}

                                    <div className="form-group col-md-6">
                                        <label>Department  <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="department"
                                            type="text"
                                            className={`form-control ${errors.department ? 'is-invalid' : ''}`}
                                            value={formData.department}
                                            onChange={handleChange}
                                            placeholder="Department"
                                        />
                                        {errors.department && <small className="text-danger">{errors.department}</small>}
                                    </div>
                                </div>
                                <label htmlFor="">Bank Details :-  <span style={{ color: "red" }}>*</span></label>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>Account Name  <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="accountName"
                                            type="text"
                                            className={`form-control ${errors.accountName ? 'is-invalid' : ''}`}
                                            value={formData.accountName}
                                            onChange={handleChange}
                                            placeholder="Account Number"
                                        />
                                        {errors.accountName && <small className="text-danger">{errors.accountName}</small>}
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label>Bank Relation  <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="accountRelation"
                                            type="text"
                                            className={`form-control ${errors.accountRelation ? 'is-invalid' : ''}`}
                                            value={formData.accountRelation}
                                            onChange={handleChange}
                                            placeholder="Account Number"
                                        />
                                        {errors.accountRelation && <small className="text-danger">{errors.accountRelation}</small>}
                                    </div>

                                    {/* Bank Name */}
                                    <div className="form-group col-md-6">
                                        <label>Bank Name  <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="bankName"
                                            type="text"
                                            className={`form-control ${errors.bankName ? 'is-invalid' : ''}`}
                                            value={formData.bankName}
                                            onChange={handleChange}
                                            placeholder="Bank Name"
                                        />
                                        {errors.bankName && <small className="text-danger">{errors.bankName}</small>}
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label>Bank Account Number  <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="accountNumber"
                                            type="number"
                                            className={`form-control ${errors.accountNumber ? 'is-invalid' : ''}`}
                                            value={formData.accountNumber}
                                            onChange={handleChange}
                                            placeholder="Account Number"
                                        />
                                        {errors.accountNumber && <small className="text-danger">{errors.accountNumber}</small>}
                                    </div>


                                    {/* Bank Branch */}
                                    <div className="form-group col-md-6">
                                        <label>Branch  <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="branch"
                                            type="text"
                                            className={`form-control ${errors.branch ? 'is-invalid' : ''}`}
                                            value={formData.branch}
                                            onChange={handleChange}
                                            placeholder="Branch"
                                        />
                                        {errors.branch && <small className="text-danger">{errors.branch}</small>}
                                    </div>

                                    {/* IFSC Code */}
                                    <div className="form-group col-md-6">
                                        <label>IFSC Code  <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="ifscCode"
                                            type="text"
                                            className={`form-control ${errors.ifscCode ? 'is-invalid' : ''}`}
                                            value={formData.ifscCode}
                                            onChange={handleChange}
                                            placeholder="IFSC Code"
                                        />
                                        {errors.ifscCode && <small className="text-danger">{errors.ifscCode}</small>}
                                    </div>
                                </div>

                                {/* Emergency Contact Name */}
                                <div className="form-group col-md-4">
                                    <label>Emergency Contact Name  <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="emergencyContactName"
                                        type="text"
                                        className={`form-control ${errors.emergencyContactName ? 'is-invalid' : ''}`}
                                        value={formData.emergencyContactName}
                                        onChange={handleChange}
                                        placeholder="Emergency Contact Name"
                                    />
                                    {errors.emergencyContactName && <small className="text-danger">{errors.emergencyContactName}</small>}
                                </div>

                                {/* Emergency Contact Number */}
                                <div className="form-group col-md-4">
                                    <label>Emergency Contact Number  <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="emergencyContactNumber"
                                        type="tel"
                                        className={`form-control ${errors.emergencyContactNumber ? 'is-invalid' : ''}`}
                                        value={formData.emergencyContactNumber}
                                        onChange={handleChange}
                                        placeholder="Emergency Contact Number"
                                    />
                                    {errors.emergencyContactNumber && <small className="text-danger">{errors.emergencyContactNumber}</small>}
                                </div>

                                {/* Emergency Contact Relation */}
                                <div className="form-group col-md-4">
                                    <label>Emergency Contact Relation  <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="emergencyContactRelation"
                                        type="text"
                                        className={`form-control ${errors.emergencyContactRelation ? 'is-invalid' : ''}`}
                                        value={formData.emergencyContactRelation}
                                        onChange={handleChange}
                                        placeholder="Emergency Contact Relation"
                                    />
                                    {errors.emergencyContactRelation && <small className="text-danger">{errors.emergencyContactRelation}</small>}
                                </div>


                                {/* Additional Information */}
                                <div className="form-group col-md-12">
                                    <label>Additional Information</label>
                                    <textarea
                                        name="additionalInformation"
                                        className="form-control"
                                        value={formData.additionalInformation}
                                        onChange={handleChange}
                                        placeholder="Additional Information"
                                    />
                                </div>
                                {/* Upload */}
                                <div className="form-group col-md-6">
                                    <label>Upload image  <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="upload1"
                                        type="file"
                                        className={`form-control-file ${errors.upload1 ? 'is-invalid' : ''}`}
                                        onChange={handleImageChange}
                                    />
                                    <small>Max Sixe:200KB</small>
                                    {errors.upload1 && <small className="text-danger">{errors.upload1}</small>}
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Upload Document</label>
                                    <input
                                        name="upload2"
                                        type="file"
                                        className="form-control-file"
                                        onChange={handleImageChange}
                                    />
                                    <small>Max Sixe:200KB</small>
                                </div>
                            </div>
                        </div>
                        <div style={{ backgroundColor: "#00509D", color: "white" }} className="modal-footer">
                            <button type="submit" className="button_details" disabled={isLoading}>
                                {isLoading ? 'Loading...' : 'Submit'}
                            </button>
                            <button type="button" className="button_details" onClick={handleClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddEmployeeTable;
