import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddClientModal from './AddClientModal';
import ClientDesc from './ClientDesc';
import EditClientModal from './EditClientModal';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import Sidebar from '../../components/sidebar/Sidebar';
import SearchBar from '../../components/sidebar/SearchBar';
import ActiveInactiveModal from '../EmployeeMaster/ActiveInactiveModal';
import "../EmployeeMaster/Employeelist.css";
import { toast, ToastContainer } from 'react-toastify'; // Import toast components
import 'react-toastify/dist/ReactToastify.css';
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner
import { debounce } from "lodash"; // Import debounce function


function ClientList({ handleLogout, username }) {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [showClientDetails, setShowClientDetails] = useState(false);
  const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editClient, setEditClient] = useState(null);
  const [deleteClient, setDeleteClient] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(25);
  const [isClientAdded, setIsClientAdded] = useState(false);
  const [countries, setCountries] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(""); // search term

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = clients.filter(client => client.clientName.toLowerCase().includes(searchTerm.toLowerCase())).slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const fetchClients = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/clients`);
      setClients(response.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }finally {
      setIsLoading(false);
  }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const handleAddClient = () => {
    setIsAddClientModalOpen(true);
  };

  const handleCloseClientModal = () => {
    setIsAddClientModalOpen(false);
    setIsEditModalOpen(false);
    setIsDeleteModalOpen(false);
  };

  const handleClientDetails = (client) => {
    setSelectedClient(client);
    setShowClientDetails(true);
  };

  const handleEditClient = (client) => {
    setEditClient(client);
    setIsEditModalOpen(true);
  };

  const handleDeleteClient = (client) => {
    setDeleteClient(client);
    setIsDeleteModalOpen(true);
  };

  const confirmDeleteClient = async (reason) => {
    try {
      await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/clients/${deleteClient.id}`);
      const deletedClient = { ...deleteClient, reason };
      await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedClient);
      setClients((prevClients) =>
        prevClients.filter((client) => client.id !== deleteClient.id)
      );
      setIsDeleteModalOpen(false);
      toast.success("delete successfully")
    } catch (error) {
      console.error("Error deleting Client:", error);
    }
  };

  const handleUpdateClient = async (updatedClient) => {
    try {
      await axios.put(`${process.env.REACT_APP_LOCAL_URL}/clients/${updatedClient.id}`, updatedClient);
      setClients((prevClients) =>
        prevClients.map((client) => (client.id === updatedClient.id ? updatedClient : client))
      );
      setIsEditModalOpen(false);
    } catch (error) {
      console.error("Error updating client:", error);
    }
  };

  const handleUpdateClients = () => {
    toast.success('Data uploaded successfully'); // Display toast notification
    fetchClients();
  };

  const handleSearchChange = debounce((event) => {
    setSearchTerm(event.target.value);
  }, 300);

  return (
    <div className='d-flex w-100 h-100 bg-white'>
      <Sidebar />
      <div className='w-100'>
        <SearchBar username={username} handleLogout={handleLogout} />
        <div className="container-fluid">
          <ToastContainer />
          {showClientDetails ? (
            <ClientDesc client={selectedClient} onClose={() => setShowClientDetails(false)} />
          ) : (
            <div className="row">
              <div className="col-xl-12">
                <div style={{ borderRadius: "20px", border: "1px solid #003459" }} className='overflow-hidden'>
                  <div style={{background: "rgb(33,131,128)", background: "#00509d " }} className="row no-gutters align-items-center p-3">
                    <div className="col">
                      <div className="text-xs font-weight-bold text-white text-uppercase d-flex align-items-center justify-content-between" style={{ fontSize: '1.5rem' }}>
                        <div className="nunito text-white">client List</div>
                        <div className=" d-flex gap-3">
                          <div className="d-flex align-items-center justify-content-center gap-2">
                            <input
                              type="text"
                              className="form-control button_details py-0"
                              placeholder="Search for clients..."
                              aria-label="Search"
                              aria-describedby="basic-addon2"
                              onChange={handleSearchChange}
                            />
                            <div className="input-group-append">
                              <button className="button_details" type="button">
                                <i className="fas fa-search fa-sm"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <button onClick={handleAddClient} className="button_details"><i className="fa fa-plus"></i> Add New Client</button>
                      </div>
                    </div>
                  </div>
                  <hr className='m-0 p-0' />
                  <div className=''>
                    <div className="card-body">
                      <div className="" style={{ maxHeight: "610px", overflowY: "auto" }}>
                        {isLoading ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <ThreeDots color="#00509d" height={80} width={80} />
                          </div>
                        ) : (
                          <table className="table table-bordered" style={{ width: "100%" }}>
                            <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                              <tr>
                                <th>Client Name</th>
                                <th>Client Code</th>
                                <th>Contact Person</th>
                                <th>Mobile No.</th>
                                <th>Email Id</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <style>
                                {`.hyperlink:hover {color: blue;}`}
                              </style>
                              {currentItems.length === 0 ? (
                                <tr>
                                  <td colSpan="12" className="text-center">No Inquiry Entries.</td>
                                </tr>
                              ) : (
                                currentItems.map((client, index) => (
                                  <tr key={index}>
                                    <td className='hyperlink' style={{ cursor: "pointer" }} onClick={() => handleClientDetails(client)}>{client.clientName}</td>
                                    <td>{client.clientCode}</td>
                                    <td>{client.contactPersonName}</td>
                                    <td>{client.mobileNo1}</td>
                                    <td>{client.emailId1}</td>
                                    <td>
                                      <div className="d-flex align-items-center justify-content-start gap-3 z-99">
                                        <div className="btn-group">
                                          <button
                                            className="button_action"
                                            type="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                          </button>
                                          <div className="dropdown-menu actionmenu">
                                            <a className="dropdown-item" href="#" onClick={() => handleClientDetails(client)}>
                                              <i className="fa fa-file"></i> Details
                                            </a>
                                            <a className="dropdown-item" href="#" onClick={() => handleEditClient(client)}>
                                              <i className="fas fa-edit"></i> Edit
                                            </a>
                                            <a className="dropdown-item" href="#" onClick={() => handleDeleteClient(client)}>
                                              <i className="fa fa-trash"></i> Delete
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        )}
                      </div>
                      <ul className="pagination mt-2">
                        <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                          <a className="page-link text-black " href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                        </li>
                        {Array.from({ length: Math.ceil(clients.length / itemsPerPage) }, (_, i) => (
                          <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                            <a className="page-link text-black " href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                          </li>
                        ))}
                        <li className={`page-item ${currentPage === Math.ceil(clients.length / itemsPerPage) && 'disabled'}`}>
                          <a className="page-link text-black " href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {isAddClientModalOpen && <AddClientModal onClose={handleCloseClientModal} onUpdate={handleUpdateClients} />}
                  {isEditModalOpen && <EditClientModal client={editClient} onClose={handleCloseClientModal} onUpdate={handleUpdateClients} />}
                  <DeleteConfirmationModal
                    isOpen={isDeleteModalOpen}
                    itemName={deleteClient ? deleteClient.clientName : ""}
                    onDelete={confirmDeleteClient}
                    onClose={() => setIsDeleteModalOpen(false)}
                    deleteReason={deleteReason}
                    setDeleteReason={setDeleteReason}
                  />
                  {isStatusModalOpen && (
                    <ActiveInactiveModal
                      client={editClient}
                      onClose={() => setIsStatusModalOpen(false)}
                      onUpdate={handleUpdateClients}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ClientList;























