import React, { useState } from 'react';
import axios from 'axios';

const AddCourier = ({ onClose, onUpdate }) => {
    const [courierCompanyName, setCourierCompanyName] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [phoneWarning, setPhoneWarning] = useState("");



    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "phone") {
            setPhoneWarning(value.length !== 10 ? "Phone number must be 10 digits" : "");
        }

        switch (name) {
            case 'courierCompanyName':
                setCourierCompanyName(value);
                break;
            case 'address':
                setAddress(value);
                break;
            case 'phone':
                setPhone(value);
                break;
            default:
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        setIsLoading(true);
        const requiredFields = ["courierCompanyName", "address", "phone"];
        for (const field of requiredFields) {
            if (!eval(field)) {
                setError(`Please fill in the ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}`);
                setIsLoading(false);
                return;
            }
        }

        setError("");

        try {
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/courier`, {
                courierCompanyName,
                address,
                phone,
                username: localStorage.getItem('username'),
            });
            console.log('Courier added successfully:', response.data);
            onUpdate();
            setTimeout(() => {
                onClose();
                window.location.reload(); // Reload the page after submission
            }, 1000);
        } catch (error) {
            console.error('Error adding courier:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div id="addCourier" className="modal fade show" role="dialog" style={{ display: "block" }}>
            <div style={{ borderRadius: "20px" }} className="modal-dialog modal-lg overflow-hidden">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
                        <div style={{ backgroundColor: "#00509D", color: "white" }} className="modal-header">
                            <h5 className="modal-title">Add Courier</h5>
                            <button type="button" className="button_details " onClick={handleClose}>
                                <i className="fa-solid fa-xmark"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            {error && <div className="alert alert-danger">{error}</div>}
                            <div className="form-group">
                                <label>Courier Company Name<span style={{ color: "red" }}>*</span></label>
                                <input name="courierCompanyName" value={courierCompanyName} onChange={handleChange} type="text" className="form-control" required placeholder="Courier Company Name" />
                            </div>
                            <div className="form-group">
                                <label>Address<span style={{ color: "red" }}>*</span></label>
                                <input name="address" value={address} onChange={handleChange} type="email" className="form-control" required placeholder="Address" />
                            </div>
                            <div className="form-group">
                                <label>Phone<span style={{ color: "red" }}>*</span></label>
                                <input name="phone" value={phone} onChange={handleChange} type="number" className="form-control" required placeholder="Phone" />
                                {phoneWarning && <small className="text-danger">{phoneWarning}</small>}
                            </div>
                        </div>
                        <div style={{ backgroundColor: "#00509D", color: "white" }} className="modal-footer">
                            <button type="submit" className="button_details" disabled={isLoading}>
                                {isLoading ? 'Loading...' : 'Submit'}
                            </button>
                            <button type="button" className="button_details" onClick={handleClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddCourier;
