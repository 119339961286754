import React, { useState, useEffect } from "react";
import axios from "axios";
import EditSales from "./EditSales";
import { ToastContainer, toast } from 'react-toastify';
import myImage from '../../images/brand_images/default_employee.jpg';
import 'react-toastify/dist/ReactToastify.css';

const SalesDesc = ({ sales, onClose }) => {
    const [salesHistory, setSalesHistory] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {
        fetchSalesHistory();
    }, [sales]);
    const fetchSalesHistory = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_LOCAL_URL}/api/sales/history/${sales.id}`
            );
            setSalesHistory(response.data);
        } catch (error) {
            console.error("Error fetching sales history:", error);
        }
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    const handleEditSales = () => {
        setIsEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
    };
    const handleUpdateSalesList = () => {
        fetchSalesHistory();
        toast.success("Data saved Successfully")
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = salesHistory.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div>
            <div className="card-body p-4 bg-white rounded shadow-sm">
                <div className="row">

                    <div className="col-md-9 d-flex  justify-content-between px-3">
                        <div>
                            <h2 style={{ color: "#00509d" }} className="title-detail fw-bolder font-bold m-0">
                                {sales.clientName}
                            </h2>
                            <h6 style={{ color: "#00509d" }} className="title-detail fw-bolder font-bold m-0">
                                Client Code: {sales.clientCode}
                            </h6>
                            <small>Created By - {sales.username || "N/A"}</small>
                        </div>
                        <div>
                            <p className="m-0">
                                <i class="fa fa-envelope" aria-hidden="true"></i> <span> Email: {sales.emailId1 || "N/A"}</span>
                            </p>
                            <p className="m-0">
                                <i class="fa fa-phone"></i> <span> Phone: {sales.mobileNo1 || "N/A"}</span>
                            </p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="p-2 barcode-inner d-flex flex-column gap-1">
                            <div className="d-flex gap-2 align-items-center justify-content-center">
                                <button style={{ whiteSpace: "nowrap" }} onClick={onClose} className="button_edit">
                                    <i className="fa fa-arrow-left"></i>   Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a
                                    style={{ color: "grey" }}
                                    className="nav-link button-link active show"
                                    id="details-tab"
                                    data-toggle="tab"
                                    href="#details"
                                    role="tab"
                                    aria-controls="details"
                                    aria-selected="true"
                                >
                                    Sales Details
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div
                                className="tab-pane fade active show"
                                id="details"
                                role="tabpanel"
                                aria-labelledby="details-tab"
                            >
                                <div className="row">
                                    <div className="col-md-9 d-flex" style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto", overflowX: "hidden" }}>
                                        <table className="table table-bordered table-hover" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td >
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Client Name:</span> {sales.clientName || "-"}</p>
                                                    </td>

                                                    <td >
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Client Code:</span> {sales.clientCode || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Email:</span> {sales.emailId1 || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Mobile No:</span>  {sales.mobileNo1 || "-"}</p>
                                                    </td>

                                                </tr>

                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Address: </span> {sales.address || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Company Name: </span> {sales.companyName || "-"}</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Sales Date: </span> {formatDate(sales.salesDate) || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Sales Description: </span> {sales.salesDescription || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Sales Executive Name: </span> {sales.salesExecutiveName || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Selected Service Products: </span>
                                                            {sales.selectedServiceProducts ? (
                                                                <ul className="mb-0">
                                                                    {JSON.parse(sales.selectedServiceProducts).map(product => (
                                                                        <li key={product.id}>{product.name}</li>
                                                                    ))}
                                                                </ul>
                                                            ) : (
                                                                <p className="mb-0 assetserial">-</p>
                                                            )}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Total Sales Amount: </span>{sales.totalSalesAmount || "-"}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* Image Section */}
                                    <div class="col-md-3 pt-2 text-center">
                                        <img
                                            src={sales.uploadPicture
                                                ? `${process.env.REACT_APP_LOCAL_URL}/uploads/sales_photo/${sales.uploadPicture}`
                                                : myImage}
                                            style={{ width: "90px" }}
                                            className="employee-image"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Sales History */}
                            <div className="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Event</th>
                                                    <th>Date</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItems.map((entry) => (
                                                    <tr key={entry.event_id}>
                                                        <td>{entry.event}</td>
                                                        <td>{formatDate(entry.date)}</td>
                                                        <td>{entry.description}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {/* Pagination */}
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                            </li>
                                            {Array.from({ length: Math.ceil(salesHistory.length / itemsPerPage) || 1 }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(salesHistory.length / itemsPerPage) && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isEditModalOpen && <EditSales
                sales={sales}
                onUpdate={handleUpdateSalesList}
                onClose={handleCloseEditModal}
            />}
        </div>
    );
};

export default SalesDesc;



























