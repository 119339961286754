import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner
import './Dashboard.css';
import Sidebar from '../components/sidebar/Sidebar';
import SearchBar from '../components/sidebar/SearchBar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddInward from './Inword/AddInward';
import AddOutward from './Outward/AddOutWard';

function Dashboard({ handleLogout, username }) {
    // for Dashboard 
    const [clients, setClients] = useState(0);
    const [vendors, setvendors] = useState(0);
    const [inward, setInward] = useState(0);
    const [outward, setOutward] = useState(0);
    // services /Products
    const [services, setServices] = useState([]);
    const [products, setProducts] = useState([]);
    // addinward modal 
    const [isAddInwardModalopen, setIsAddInwardModalopen] = useState(false);
    // add outwardModal 
    const [isAddOutwardModalOpen, setIsAddOutwardModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchClients();
        fetchVendors();
        fetchInwards();
        fetchOutwards();
        fetchServices();
        fetchProducts();
    }, []);
    const fetchClients = async () => {
        setIsLoading(true);
        try {
            const clientResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/clients`);
            console.log(clientResponse.data);
            if (Array.isArray(clientResponse.data)) {
                setClients(clientResponse.data.length);
            } else {
                console.error('Invalid response format for total asset count');
            }
        } catch (error) {
            console.error('Error fetching clients:', error);
        } finally {
            setIsLoading(false);
        }
    };
    const fetchVendors = async () => {
        setIsLoading(true);
        try {
            const vendorResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/vendors`);
            console.log(vendorResponse.data);
            if (Array.isArray(vendorResponse.data)) {
                setvendors(vendorResponse.data.length);
            } else {
                console.error('Invalid response format for total asset count');
            }
        } catch (error) {
            console.error('Error fetching clients:', error);
        } finally {
            setIsLoading(false);
        }
    };
    const fetchInwards = async () => {
        setIsLoading(true);
        try {
            const inwardResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/inwards`);
            console.log(inwardResponse.data);
            if (Array.isArray(inwardResponse.data)) {
                setInward(inwardResponse.data.length);
            } else {
                console.error('Invalid response format for total asset count');
            }
        } catch (error) {
            console.error('Error fetching clients:', error);
        } finally {
            setIsLoading(false);
        }
    };
    const fetchOutwards = async () => {
        setIsLoading(true);
        try {
            const inwardResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/outwards`);
            console.log(inwardResponse.data);
            if (Array.isArray(inwardResponse.data)) {
                setOutward(inwardResponse.data.length);
            } else {
                console.error('Invalid response format for total asset count');
            }
        } catch (error) {
            console.error('Error fetching clients:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchServices = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/service_product`);
            const services = response.data.filter(item => item.type === "Service");
            setServices(services);
        } catch (error) {
            console.error('Error fetching Services:', error);
        } finally {
            setIsLoading(false);
        }
    };
    const fetchProducts = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/service_product`);
            const Product = response.data.filter(item => item.type === "Product");
            setProducts(Product);
        } catch (error) {
            console.error("Error fetching products:", error);
        } finally {
            setIsLoading(false);
        }
    };


    // Add Inward Modal 
    const handleAddInward = () => {
        setIsAddInwardModalopen(true);
    };

    const handleCloseInwardModal = () => {
        setIsAddInwardModalopen(false);
    };

    // Out Ward Modal 
    const handleAddOutWardModal = () => {
        setIsAddOutwardModalOpen(true);
    };

    const handleCloseOutWardModal = () => {
        setIsAddOutwardModalOpen(false);
    };

    const handleUpdate = () => {
        toast.success("successfully uploaded");
    }

    return (
        <div className='d-flex w-100 h-100 bg-white '>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} /> {/* Pass username and handleLogout props */}
                <div className="container-fluid px-3">
                    <ToastContainer />
                    <div className=' bg-white rounded p-3 shadow'>
                        <div style={{ borderRadius: "10px", background: "rgb(33,131,128)", background: "#00509d" }} className="d-sm-flex align-items-center justify-content-between shadow-sm mb-4 p-3 ">
                            <h3 style={{ color: "white" }} className="title-detail fw-bolder font-bold m-0">
                                Dashboard
                            </h3>
                            <div className='d-flex gap-4'>
                                <button type="button" className=" userbutton" onClick={handleAddInward} >
                                    Add Inward
                                </button>
                                <button type="button" className=" userbutton" onClick={handleAddOutWardModal} >
                                    Add Outward
                                </button>
                            </div>
                        </div>
                        {/* Content Row */}
                        <div className="row px-3 mb-3">
                            <div style={{ width: "100%" }} className='p-0 d-flex align-items-center justify-content-between'>
                                <div style={{ width: "22%", background: "#257180", borderRadius: "20px" }} className=' p-3'>
                                    <div className="row no-gutters align-items-center">
                                        <div className="col">
                                            <div className="text-xs font-weight-bold text-white text-uppercase mb-2 d-flex align-items-center justify-content-between" style={{ fontSize: '1rem' }}>
                                                <i className="fa-solid fa-users"></i>
                                                <span><Link className='text-white' to="/clientlist"><i style={{ rotate: "45deg" }} className=" fa-solid fa-circle-arrow-up"></i></Link></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="nunito text-white" >
                                                Total Clients
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='m-0 p-0' />
                                    <div className='mt-2'>
                                        {isLoading ? (
                                            <div className="d-flex justify-content-center align-items-center">
                                                {/* Correct usage of spinner */}
                                                <ThreeDots
                                                    color="#00509d"
                                                    height={80}
                                                    width={80}
                                                />
                                            </div>
                                        ) : (
                                            <h3 style={{ fontSize: "2vw" }} className="text-end text-white fw-bolder m-0">
                                                {clients}
                                            </h3>)} {/* Updated amount */}
                                    </div>
                                </div>
                                <div style={{ width: "22%", background: "#134B70", borderRadius: "20px" }} className=' p-3'>
                                    <div className="row no-gutters align-items-center">
                                        <div className="col">
                                            <div className="text-xs font-weight-bold text-white text-uppercase mb-2 d-flex align-items-center justify-content-between" style={{ fontSize: '1rem' }}>
                                                <i className="fa-solid fa-users"></i>
                                                <span><Link className='text-white' to="/vendorlist"><i style={{ rotate: "45deg" }} className=" fa-solid fa-circle-arrow-up"></i></Link></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="nunito text-white" >
                                                Total Vendor
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='m-0 p-0' />
                                    <div className='mt-2'>
                                        {isLoading ? (
                                            <div className="d-flex justify-content-center align-items-center">
                                                {/* Correct usage of spinner */}
                                                <ThreeDots
                                                    color="#00509d"
                                                    height={80}
                                                    width={80}
                                                />
                                            </div>
                                        ) : (
                                            <h3 style={{ fontSize: "2vw" }} className="text-end text-white fw-bolder m-0">
                                                {vendors}
                                            </h3>)} {/* Updated amount */}
                                    </div>
                                </div>
                                <div style={{ width: "22%", background: "green", borderRadius: "20px" }} className=' p-3'>
                                    <div className="row no-gutters align-items-center">
                                        <div className="col">
                                            <div className="text-xs font-weight-bold text-white text-uppercase mb-2 d-flex align-items-center justify-content-between" style={{ fontSize: '1rem' }}>
                                                <i class="fa-solid fa-diagram-project"></i>
                                                <span><Link className='text-white' to="/inwardlist"><i style={{ rotate: "45deg" }} className=" fa-solid fa-circle-arrow-up"></i></Link></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="nunito text-white" >
                                                Total Inward Post
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='m-0 p-0' />
                                    <div className='mt-2'>
                                        {isLoading ? (
                                            <div className="d-flex justify-content-center align-items-center">
                                                {/* Correct usage of spinner */}
                                                <ThreeDots
                                                    color="#00509d"
                                                    height={80}
                                                    width={80}
                                                />
                                            </div>
                                        ) : (
                                            <h3 style={{ fontSize: "2vw" }} className="text-end text-white fw-bolder m-0">
                                                {inward}
                                            </h3>)} {/* Updated amount */}
                                    </div>
                                </div>
                                <div style={{ width: "22%", background: "red", borderRadius: "20px" }} className=' p-3'>
                                    <div className="row no-gutters align-items-center">
                                        <div className="col">
                                            <div className="text-xs font-weight-bold text-white text-uppercase mb-2 d-flex align-items-center justify-content-between" style={{ fontSize: '1rem' }}>
                                                <i class="fa-solid fa-diagram-project"></i>
                                                <span><Link className='text-white' to="/outwardlist"><i style={{ rotate: "45deg" }} className=" fa-solid fa-circle-arrow-up"></i></Link></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="nunito text-white" >
                                                Total Outward Post
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='m-0 p-0' />
                                    <div className='mt-2'>
                                        {isLoading ? (
                                            <div className="d-flex justify-content-center align-items-center">
                                                {/* Correct usage of spinner */}
                                                <ThreeDots
                                                    color="#00509d"
                                                    height={80}
                                                    width={80}
                                                />
                                            </div>
                                        ) : (
                                            <h3 style={{ fontSize: "2vw" }} className="text-end text-white fw-bolder m-0">
                                                {outward}
                                            </h3>)} {/* Updated amount */}
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* content row   */}
                        <div className="row px-3 mb-3">
                            <div style={{ width: "100%" }} className='p-0 d-flex justify-content-between'>
                                <div style={{ width: "49%", borderRadius: "20px", border: "1px solid #003459" }} className='overflow-hidden'>
                                    <div style={{ background: "rgb(33,131,128)", background: "#00509d", }} className="row no-gutters align-items-center px-4 py-2">
                                        <div className="col">
                                            <div className="text-xs font-weight-bold text-white text-uppercase d-flex align-items-center justify-content-between" style={{ fontSize: '1.5rem' }}>
                                                <div className="nunito text-white" >
                                                    Total Services - {services.length}
                                                </div>
                                                <span><Link className='text-white' to="/servicelist"><i class="fa-solid fa-bell"></i></Link></span>

                                            </div>
                                        </div>
                                    </div>
                                    <hr className='m-0 p-0' />
                                    <div className='p-1'>
                                        <div
                                            // className='forresponsive'
                                            style={{ height: "270px", overflowY: "auto", overflowX: "hidden" }}
                                        >
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    {/* Correct usage of spinner */}
                                                    <ThreeDots
                                                        color="#00509d"
                                                        height={80}
                                                        width={80}
                                                    />
                                                </div>
                                            ) : services.length === 0 ? (
                                                <p className="nunito text-black text-center text-muted">No Services</p>
                                            ) : (
                                                services.map((service, index) => (
                                                    <div key={index}
                                                        style={{ border: "1px solid #003459", borderRadius: "10px", background: "rgb(33,131,128)", background: "#00509d" }}
                                                        className="p-1 m-1 text-white"
                                                    >
                                                        <div className="d-flex align-items-start p-1 ">
                                                            <div className='w-100'>
                                                                <div className='d-flex justify-content-between'>
                                                                    <div className=''>
                                                                        <p className=" nunito m-0 p-0">
                                                                            {service.name}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: "49%", borderRadius: "20px", border: "1px solid #003459" }} className='overflow-hidden'>
                                    <div style={{ background: "rgb(33,131,128)", background: "#00509d", }} className="row no-gutters align-items-center px-4 py-2">
                                        <div className="col">
                                            <div className="text-xs font-weight-bold text-white text-uppercase d-flex align-items-center justify-content-between" style={{ fontSize: '1.5rem' }}>
                                                <div className="nunito text-white" >
                                                    Total Products - {products.length}
                                                </div>
                                                <span><Link className='text-white' to="/productlist"><i class="fa-solid fa-bell"></i></Link></span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='m-0 p-0' />
                                    <div className='p-1'>
                                        <div
                                            // className='forresponsive'
                                            style={{ height: "270px", overflowY: "auto", overflowX: "hidden" }}
                                        >
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    {/* Correct usage of spinner */}
                                                    <ThreeDots
                                                        color="#00509d"
                                                        height={80}
                                                        width={80}
                                                    />
                                                </div>
                                            ) : products.length === 0 ? (
                                                <p className="nunito text-black text-center text-muted">No products</p>
                                            ) : (
                                                products.map((product, index) => (
                                                    <div key={index}
                                                        style={{ border: "1px solid #003459", borderRadius: "10px", background: "rgb(33,131,128)", background: "#00509d" }}
                                                        className="p-1 m-1 text-white"
                                                    >
                                                        <div className="d-flex align-items-start p-1 ">
                                                            <div className='w-100'>
                                                                <div className='d-flex justify-content-between'>
                                                                    <div className=''>
                                                                        <p className=" nunito m-0 p-0">
                                                                            {product.name}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {isAddInwardModalopen && <AddInward onClose={handleCloseInwardModal} onUpdate={handleUpdate} />}
                                {isAddOutwardModalOpen && <AddOutward onClose={handleCloseOutWardModal} onUpdate={handleUpdate} />}

                            </div>
                        </div>

                        {/* content row   */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;











