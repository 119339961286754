import React, { useState } from "react";
import axios from "axios";

const NextInquiry = ({ onClose, onUpdate, inquiry }) => {
    const [formData, setFormData] = useState({
        callingDate: "",
        description: "",
        nextDate: "",
        username: localStorage.getItem('username'),
    });
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validate = () => {
        let formErrors = {};
        if (!formData.callingDate) formErrors.callingDate = 'Calling Date is required';
        if (!formData.nextDate) formErrors.nextDate = 'Next Date is required';

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);


        if (!validate()) {
            return;
        }

        try {
            // Posting new inquiry history
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/next_inquiry`, {
                ...formData,
                inquiryId: inquiry.id,
            });

            // Updating nextCallDate in inquiry_details
            await axios.put(`${process.env.REACT_APP_LOCAL_URL}/update/next_date/${inquiry.id}`, {
                nextCallDate: formData.nextDate,
            });

            console.log("Next inquiry details uploaded successfully");
            onUpdate();
            setTimeout(() => {
                onClose();
                window.location.reload(); // Reload the page after submission
            }, 1000);
        } catch (error) {
            console.error("Error uploading next inquiry details:", error);
        }finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div
            id="nextInquiry"
            className="modal fade show"
            role="dialog"
            style={{ display: "block", paddingRight: "17px" }}
        >
            <div style={{ borderRadius: "20px" }} className="modal-dialog modal-lg overflow-hidden">
                <div className="modal-content">
                    <form
                        id="formNextInquiry"
                        autoComplete="off"
                        noValidate="novalidate"
                        onSubmit={handleSubmit}
                    >
                        <div style={{ backgroundColor: "#00509D", color: "white" }} className="modal-header">
                            <h5 className="modal-title">Add Next Inquiry Details</h5>
                            <button type="button" className="button_details " onClick={handleClose}>
                                <i className="fa-solid fa-xmark"></i>
                            </button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                            <div className="form-group">
                                <label>Calling Date <span style={{ color: "red" }}>*</span></label>
                                <input
                                    name="callingDate"
                                    type="date"
                                    className={`form-control ${errors.callingDate ? 'is-invalid' : ''}`}
                                    value={formData.callingDate}
                                    onChange={handleChange}
                                    required
                                    placeholder="Calling Date"
                                />
                                {errors.callingDate && <small className="text-danger">{errors.callingDate}</small>}
                            </div>
                            <div className="form-group">
                                <label>Description</label>
                                <textarea
                                    name="description"
                                    className="form-control"
                                    rows="5"
                                    value={formData.description}
                                    onChange={handleChange}
                                    placeholder="Description"
                                ></textarea>
                            </div>
                            <div className="form-group">
                                <label>Next Date <span style={{ color: "red" }}>*</span></label>
                                <input
                                    name="nextDate"
                                    type="date"
                                    className={`form-control ${errors.nextDate ? 'is-invalid' : ''}`}
                                    value={formData.nextDate}
                                    onChange={handleChange}
                                    required
                                    placeholder="Next Date"
                                />
                                {errors.nextDate && <small className="text-danger">{errors.nextDate}</small>}
                            </div>
                        </div>
                        <div style={{ backgroundColor: "#00509D", color: "white" }} className="modal-footer">
                            <button type="submit" className="button_details" disabled={isLoading}>
                                {isLoading ? 'Loading...' : 'Submit'}
                            </button>
                            <button type="button" className="button_details" onClick={handleClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default NextInquiry;
