import React, { useState, useEffect } from "react";
import axios from "axios";
import AddCourier from "./AddCourier";
import CourierDesc from "./CourierDesc";
import EditCourier from './EditCourier';
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import SearchBar from "../../components/sidebar/SearchBar";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner'; // Spinner
import 'react-toastify/dist/ReactToastify.css';

function CourierList({ handleLogout, username }) {
    const [isLoading, setIsLoading] = useState(false);
    const [couriers, setCouriers] = useState([]);
    const [selectedCourier, setSelectedCourier] = useState(null);
    const [showCourierDetails, setShowCourierDetails] = useState(false);
    const [isAddCourierModalOpen, setIsAddCourierModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editCourier, setEditCourier] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [deleteCourier, setDeleteCourier] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");

    useEffect(() => {
        fetchCouriers();
    }, []);

    const fetchCouriers = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/couriers`);
            setCouriers(response.data);
        } catch (error) {
            console.error("Error fetching couriers:", error);
        }finally {
            setIsLoading(false);
        }
    };

    const handleAddCourier = () => {
        setIsAddCourierModalOpen(true);
    };

    const handleCloseCourierModal = () => {
        setIsAddCourierModalOpen(false);
        setIsEditModalOpen(false);
    };

    const handleCourierDetails = (courier) => {
        setSelectedCourier(courier);
        setShowCourierDetails(true);
    };

    const handleEditCourierClick = (courier) => {
        setEditCourier(courier);
        setSelectedCourier(courier);
        setIsEditModalOpen(true);
    };

    const handleEditCourierClose = () => {
        setSelectedCourier(null);
    };

    const handleBackToTable = () => {
        setSelectedCourier(null);
        setShowCourierDetails(false);
    };

    const handleDeleteCourier = (courier) => {
        setDeleteCourier(courier);
        setIsDeleteModalOpen(true);
    };

    const handleUpdateCourier = async (updatedCourier) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/couriers/${updatedCourier.id}`, updatedCourier);
            console.log("Courier updated:", response.data);
            const updatedCouriers = couriers.map(courier => (courier.id === updatedCourier.id ? response.data : courier));
            setCouriers(updatedCouriers);
        } catch (error) {
            console.error("Error updating courier:", error);
        }
    };

    const handleDeleteConfirmation = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/couriers/${deleteCourier.id}`);
            const deletedCourier = { ...deleteCourier, reason: deleteReason };
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedCourier);
            setCouriers(prevCouriers => prevCouriers.filter(courier => courier.id !== deleteCourier.id));
            setIsDeleteModalOpen(false);
            console.log("Courier deleted successfully");
        } catch (error) {
            console.error("Error deleting courier:", error);
        }
    };

    const handleUpdateCouriers = () => {
        toast.success("Successfully uploaded");
        fetchCouriers();
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = couriers.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className='d-flex w-100 h-100 bg-white'>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    {!showCourierDetails && (
                        <div className="row">
                            <div className="col-xl-12">
                                <div style={{ borderRadius: "20px", border: "1px solid #003459" }} className='overflow-hidden'>
                                    <div style={{background: "rgb(33,131,128)", background: "#00509d " }} className="row no-gutters align-items-center p-3">
                                        <div className="col">
                                            <div className="text-xs font-weight-bold text-white text-uppercase d-flex align-items-center justify-content-between" style={{ fontSize: '1.5rem' }}>
                                                <div className="nunito text-white">Courier List</div>
                                                <button onClick={handleAddCourier} className="button_details"><i className="fa fa-plus"></i> Add New Courier</button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='m-0 p-0' />
                                    <div className=''>
                                        <div className="card-body">
                                            <div className="" style={{ maxHeight: "610px", overflowY: "auto" }}>
                                                {isLoading ? (
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <ThreeDots color="#00509d" height={80} width={80} />
                                                    </div>
                                                ) : (
                                                    <table className="table table-bordered" style={{ width: "100%" }}>
                                                        <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                            <tr>
                                                                <th>Courier Name</th>
                                                                <th>Address</th>
                                                                <th>Contact No.</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <style>
                                                                {`.hyperlink:hover {color: blue;}`}
                                                            </style>
                                                            {currentItems.length === 0 ? (
                                                                <tr>
                                                                    <td colSpan="12" className="text-center">No Inquiry Entries.</td>
                                                                </tr>
                                                            ) : (
                                                                currentItems.map((courier, index) => (
                                                                    <tr key={index}>
                                                                        <td>{courier.courierCompanyName}</td>
                                                                        <td>{courier.address}</td>
                                                                        <td>{courier.phone}</td>

                                                                    </tr>
                                                                ))
                                                            )}
                                                        </tbody>
                                                    </table>
                                                )}
                                            </div>
                                            <ul className="pagination mt-2">
                                                <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                                </li>
                                                {Array.from({ length: Math.ceil(couriers.length / itemsPerPage) }, (_, i) => (
                                                    <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                        <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                                                    </li>
                                                ))}
                                                <li className={`page-item ${currentPage === Math.ceil(couriers.length / itemsPerPage) && 'disabled'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {showCourierDetails && selectedCourier && (
                                        <CourierDesc
                                            courier={selectedCourier}
                                            onClose={handleBackToTable}
                                        />
                                    )}
                                    {selectedCourier && !showCourierDetails && (
                                        <EditCourier courier={selectedCourier} onClose={handleEditCourierClose} onUpdate={handleUpdateCourier} />
                                    )}
                                    {isAddCourierModalOpen && <AddCourier onClose={handleCloseCourierModal} onUpdate={handleUpdateCouriers} />}
                                    <DeleteConfirmationModal
                                        isOpen={isDeleteModalOpen}
                                        itemName={deleteCourier ? deleteCourier.courierName : ""}
                                        onDelete={handleDeleteConfirmation}
                                        onClose={() => setIsDeleteModalOpen(false)}
                                        deleteReason={deleteReason}
                                        setDeleteReason={setDeleteReason}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CourierList;


















