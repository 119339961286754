import React, { useState, useEffect } from "react";
import axios from "axios";
import AddVendor from "./AddVendor";
import VendorDesc from "./VendorDesc";
import EditVendor from './EditVendor';
import DeleteConfirmationModal from "../DeleteConfirmationModal"; // Import the new component
import SearchBar from "../../components/sidebar/SearchBar";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';

function Vendorlist({ handleLogout, username }) {
    const [vendors, setVendors] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [showVendorDetails, setShowVendorDetails] = useState(false);
    const [isAddVendorModalOpen, setIsAddVendorModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editVendor, setEditVendor] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [deleteVendor, setDeleteVendor] = useState(null); // State to store data of vendor being deleted
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // State to manage delete confirmation modal
    const [deleteReason, setDeleteReason] = useState(""); // State to store deletion reason
    const [searchTerm, setSearchTerm] = useState(""); // State to store search term
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchVendors();
    }, []);

    const fetchVendors = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/vendors`);
            setVendors(response.data);
        } catch (error) {
            console.error("Error fetching vendors:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddVendor = () => {
        setIsAddVendorModalOpen(true);
    };

    const handleCloseVendorModal = () => {
        setIsAddVendorModalOpen(false);
        setIsEditModalOpen(false);
    };

    const handleVendorDetails = (vendor) => {
        setSelectedVendor(vendor);
        setShowVendorDetails(true);
    };

    const handleEditVendorClick = (vendor) => {
        setEditVendor(vendor);
        setSelectedVendor(vendor); // Update selectedVendor state
        setIsEditModalOpen(true);
    };

    const handleEditVendorClose = () => {
        setSelectedVendor(null);
    };

    const handleBackToTable = () => {
        setSelectedVendor(null);
        setShowVendorDetails(false);
    };

    const handleDeleteVendor = (vendor) => {
        setDeleteVendor(vendor);
        setIsDeleteModalOpen(true);
    };
    const handleDeleteConfirmation = async () => {
        try {
            // Perform deletion in the database
            await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/vendors/${deleteVendor.id}`);

            // Save the deleted data to delete_details table
            const deletedVendor = { ...deleteVendor, reason: deleteReason };
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedVendor);

            // Remove the deleted site from the UI
            setVendors((prevVendors) =>
                prevVendors.filter((vendor) => vendor.id !== deleteVendor.id)
            );
            // Close the delete modal
            setIsDeleteModalOpen(false);
            toast.success("Deleted Successfully")
            console.log("Vendor deleted successfully");
        } catch (error) {
            console.error("Error deleting vendor:", error);
        }
    };

    const handleUpdateVendors = () => {
        toast.success("successfully uploaded");
        fetchVendors();
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = vendors.filter(vendor => vendor.vendorName.toLowerCase().includes(searchTerm.toLowerCase())).slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <div className='d-flex w-100 h-100 bg-white'>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    {showVendorDetails && selectedVendor ?(
                        <VendorDesc
                            vendor={selectedVendor}
                            onClose={handleBackToTable}
                        />
                    ) : (
                    <div className="row">
                        <div className="col-xl-12">
                            <div style={{ borderRadius: "20px", border: "1px solid #003459" }} className='overflow-hidden'>
                                <div style={{ background: "rgb(33,131,128)", background: "#00509d " }} className="row no-gutters align-items-center p-3">
                                    <div className="col">
                                        <div className="text-xs font-weight-bold text-white text-uppercase d-flex align-items-center justify-content-between" style={{ fontSize: '1.5rem' }}>
                                            <div className="nunito text-white">Vendor List</div>
                                            <div className=" d-flex gap-3">
                                                <div className="d-flex align-items-center justify-content-center gap-2">
                                                    <input
                                                        type="text"
                                                        className="form-control button_details py-0"
                                                        placeholder="Search for clients..."
                                                        aria-label="Search"
                                                        aria-describedby="basic-addon2"
                                                        onChange={handleSearchChange}
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="button_details" type="button">
                                                            <i className="fas fa-search fa-sm"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <button onClick={handleAddVendor} className="button_details"><i className="fa fa-plus"></i> Add New Vendor</button>
                                        </div>
                                    </div>
                                </div>
                                <hr className='m-0 p-0' />
                                <div className=''>
                                    <div className="card-body">
                                        <div className="" style={{ maxHeight: "610px", overflowY: "auto" }}>
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <ThreeDots color="#00509d" height={80} width={80} />
                                                </div>
                                            ) : (
                                                <table className="table table-bordered" style={{ width: "100%" }}>
                                                    <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                        <tr>
                                                            <th>Vendor Name</th>
                                                            <th>Company Code</th>
                                                            <th>Contact Person</th>
                                                            <th>Mobile No.</th>
                                                            <th>Email ID</th>
                                                            <th>City</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <style>
                                                            {`.hyperlink:hover {color: blue;}`}
                                                        </style>
                                                        {currentItems.length === 0 ? (
                                                            <tr>
                                                                <td colSpan="12" className="text-center">No Inquiry Entries.</td>
                                                            </tr>
                                                        ) : (
                                                            currentItems.map((vendor, index) => (
                                                                <tr key={index}>
                                                                    <td className='hyperlink' style={{ cursor: "pointer" }} onClick={() => handleVendorDetails(vendor)}>{vendor.vendorName}</td>
                                                                    <td>{vendor.vendorCode}</td>
                                                                    <td>{vendor.contactPersonName1}</td>
                                                                    <td>{vendor.mobileNo1}</td>
                                                                    <td>{vendor.emailId1}</td>
                                                                    <td>{vendor.city}</td>
                                                                    <td>
                                                                        <div className="d-flex align-items-center justify-content-start gap-3 z-99">
                                                                            <div className="btn-group">
                                                                                <button
                                                                                    className="button_action"
                                                                                    type="button"
                                                                                    data-toggle="dropdown"
                                                                                    aria-haspopup="true"
                                                                                    aria-expanded="false"
                                                                                >
                                                                                    <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                                                                </button>
                                                                                <div className="dropdown-menu actionmenu">
                                                                                    <a className="dropdown-item" href="#" onClick={() => handleVendorDetails(vendor)}>
                                                                                        <i className="fa fa-file"></i> Details
                                                                                    </a>
                                                                                    <a className="dropdown-item" href="#" onClick={() => handleEditVendorClick(vendor)}>
                                                                                        <i className="fas fa-edit"></i> Edit
                                                                                    </a>
                                                                                    <a className="dropdown-item" href="#" onClick={() => handleDeleteVendor(vendor)}>
                                                                                        <i className="fa fa-trash"></i> Delete
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        )}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                        <ul className="pagination mt-2">
                                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                            </li>
                                            {Array.from({ length: Math.ceil(vendors.length / itemsPerPage) }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(vendors.length / itemsPerPage) && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* {showVendorDetails && selectedVendor && (
                                        <VendorDesc
                                            vendor={selectedVendor}
                                            onClose={handleBackToTable}
                                        />
                                    )} */}
                                {selectedVendor && !showVendorDetails && (
                                    <EditVendor vendor={selectedVendor} onClose={handleEditVendorClose} onUpdate={handleUpdateVendors} />
                                )}
                                {isAddVendorModalOpen && <AddVendor onClose={handleCloseVendorModal} onUpdate={handleUpdateVendors} />}
                                <DeleteConfirmationModal
                                    isOpen={isDeleteModalOpen}
                                    itemName={deleteVendor ? deleteVendor.vendorName : ""}
                                    onDelete={handleDeleteConfirmation}
                                    onClose={() => setIsDeleteModalOpen(false)}
                                    deleteReason={deleteReason} // Corrected prop name
                                    setDeleteReason={setDeleteReason} // Corrected prop name
                                />
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Vendorlist;




















