import React from 'react'

const EditProduct = () => {
  return (
    <div>
      <h3>EditProduct</h3>
    </div>
  )
}

export default EditProduct
