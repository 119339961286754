import React, { useState, useEffect } from "react";
import axios from 'axios';
import imageCompression from 'browser-image-compression';

const EditOutward = ({ onClose, onUpdate, outward }) => {
    const [formData, setFormData] = useState(outward);
    const [clients, setClients] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [courierCompanies, setCourierCompanies] = useState([]);
    const [ourOffices, setOurOffices] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [states, setStates] = useState([]);
    const [errors, setErrors] = useState({});
    const [isClient, setIsClient] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchClients();
        fetchVendors();
        fetchCourierCompanies();
        fetchOurOffices();
        fetchEmployees();
        fetchStates();
    }, []);

    const fetchClients = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/clients`);
            setClients(response.data);
        } catch (error) {
            console.error("Error fetching Clients:", error);
        }
    };

    const fetchVendors = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/vendors`);
            setVendors(response.data);
        } catch (error) {
            console.error("Error fetching Vendors:", error);
        }
    };

    const fetchCourierCompanies = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/couriers`);
            setCourierCompanies(response.data);
        } catch (error) {
            console.error("Error fetching Courier Companies:", error);
        }
    };

    const fetchOurOffices = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/offices`);
            setOurOffices(response.data);
        } catch (error) {
            console.error("Error fetching Our Offices:", error);
        }
    };

    const fetchEmployees = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
            setEmployees(response.data);
        } catch (error) {
            console.error("Error fetching Employees:", error);
        }
    };

    const fetchStates = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/states`);
            setStates(response.data);
        } catch (error) {
            console.error("Error fetching states:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "entityName") {
            const selectedEntity = isClient
                ? clients.find(entity => entity.clientName === value)
                : vendors.find(entity => entity.vendorName === value);

            setFormData({
                ...formData,
                [name]: value,
                client_Id: isClient && selectedEntity ? selectedEntity.id : "",
                vendor_Id: !isClient && selectedEntity ? selectedEntity.id : "",
                code: selectedEntity ? selectedEntity.clientCode || selectedEntity.vendorCode : "",
                emailId1: selectedEntity ? selectedEntity.emailId1 || selectedEntity.emailId1 : "",
                address: selectedEntity
                    ? `${selectedEntity.address1 || selectedEntity.address}, ${selectedEntity.clientCity1 || selectedEntity.city}, ${selectedEntity.clientState1 || selectedEntity.state}, ${selectedEntity.clientPincode1 || selectedEntity.pincode}`
                    : ""
            });
        } else if (name === "courierCompany") {
            const selectedCourier = courierCompanies.find(company => company.courierCompanyName === value);
            setFormData({
                ...formData,
                [name]: value,
                courierCompany_id: selectedCourier ? selectedCourier.id : "",
            });
        } else if (name === "shipFrom") {
            const selectedShipFrom = ourOffices.find(office => office.officeName === value);
            setFormData({
                ...formData,
                [name]: value,
                office_id: selectedShipFrom ? selectedShipFrom.id : "",
            });
        } else if (name === "ename") {
            const selectedEmployee = employees.find(employee => employee.ename === value);
            setFormData({
                ...formData,
                [name]: value,
                employee_id: selectedEmployee ? selectedEmployee.id : "",
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleImageChange = async (e, fieldName) => {
        const file = e.target.files[0];
        try {
            const options = {
                maxSizeMB: 0.2,
                maxWidthOrHeight: 800,
                useWebWorker: true,
            };
            const compressedFile = await imageCompression(file, options);
            const fileWithMetaData = new File([compressedFile], file.name, { type: compressedFile.type });
            setFormData({ ...formData, [fieldName]: fileWithMetaData }); // Set the corresponding field to the compressed file
        } catch (error) {
            console.error("Error compressing image:", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const formDataToSend = new FormData();
            for (const key in formData) {
                if (key !== 'photo') {
                    formDataToSend.append(key, formData[key]);
                }
            }
            formDataToSend.append("photo", formData.photo);

            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/outwards/${outward.id}`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log("outward data updated successfully:", response.data);
            onUpdate();
            setTimeout(() => {
                onClose();
                window.location.reload(); // Reload the page after submission
            }, 1000);
        } catch (error) {
            console.error("Error updating outward data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div id="editoutward" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div style={{ borderRadius: "20px" }} className="modal-dialog modal-lg overflow-hidden">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} encType="multipart/form-data" autoComplete="off" noValidate="novalidate">
                        <div style={{ backgroundColor: "#00509D", color: "white" }} className="modal-header">
                            <h5 className="modal-title">Edit outward</h5>
                            <button type="button" className="button_details " onClick={handleClose}>
                                <i className="fa-solid fa-xmark"></i>
                            </button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                            {/* <div className="form-group">
                                <label htmlFor="entityType">Entity Type</label>
                                <select
                                    name="entityType"
                                    id="entityType"
                                    className="form-control"
                                    value={isClient ? "Client" : "Vendor"}
                                    onChange={(e) => setIsClient(e.target.value === "Client")}
                                >
                                    <option value="Client">Client</option>
                                    <option value="Vendor">Vendor</option>
                                </select>
                            </div> */}
                            <div className="form-row">
                                <div className="form-group col-md-8">
                                    <label htmlFor="entityName">{isClient ? "Client" : "Vendor"} Name <span style={{ color: "red" }}>*</span></label>
                                    <select
                                        name="entityName"
                                        id="entityName"
                                        className="form-control"
                                        value={formData.entityName}
                                        onChange={handleChange}
                                        required
                                        readOnly
                                    >
                                        <option value="" disabled hidden>Select {isClient ? "Client" : "Vendor"}</option>
                                        {(isClient ? clients : vendors).map((entity) => (
                                            <option key={entity.id} value={isClient ? entity.clientName : entity.vendorName}>
                                                {isClient ? entity.clientName : entity.vendorName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="code">{isClient ? "Client" : "Vendor"} Code <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        type="text"
                                        id="code"
                                        name="code"
                                        className={`form-control ${errors.code ? 'is-invalid' : ''}`}
                                        value={formData.code}
                                        placeholder={isClient ? "Client Code" : "Vendor Code"}
                                        readOnly
                                    />
                                    {errors.clientCode && <small className="text-danger">{errors.clientCode}</small>}
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="address">Address <span style={{ color: "red" }}>*</span></label>
                                <input
                                    type="text"
                                    id="address"
                                    name="address"
                                    className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                                    value={formData.address}
                                    placeholder="Address"
                                    readOnly
                                />
                                {errors.address && <small className="text-danger">{errors.address}</small>}
                            </div>

                            <hr />

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="consignmentType">Consignment Subject <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        type="text"
                                        id="consignmentType"
                                        name="consignmentType"
                                        className={`form-control ${errors.consignmentType ? 'is-invalid' : ''}`}
                                        value={formData.consignmentType}
                                        onChange={handleChange}
                                        placeholder="Enter consignment Subject"
                                        required
                                    /> {errors.consignmentType && <small className="text-danger">{errors.consignmentType}</small>}
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="courierCompany">Courier Company <span style={{ color: "red" }}>*</span></label>
                                    <select
                                        name="courierCompany"
                                        id="courierCompany"
                                        className={`form-control ${errors.courierCompany ? 'is-invalid' : ''}`}
                                        value={formData.courierCompany}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="" disabled>Select Courier Company</option>
                                        {courierCompanies.map((company) => (
                                            <option key={company.id} value={company.courierCompanyName}>
                                                {company.courierCompanyName}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.courierCompany && <small className="text-danger">{errors.courierCompany}</small>}
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="consignmentNo">Consignment No <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        type="number"
                                        id="consignmentNo"
                                        name="consignmentNo"
                                        className={`form-control ${errors.consignmentNo ? 'is-invalid' : ''}`}
                                        value={formData.consignmentNo}
                                        onChange={handleChange}
                                        placeholder="Enter consignment number"
                                    />
                                    {errors.consignmentNo && <small className="text-danger">{errors.consignmentNo}</small>}
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="consignmentdate">Consignment Date <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        type="date"
                                        id="consignmentdate"
                                        name="consignmentdate"
                                        className={`form-control ${errors.consignmentdate ? 'is-invalid' : ''}`}
                                        value={formData.consignmentdate}
                                        onChange={handleChange}
                                    />
                                    {errors.consignmentdate && <small className="text-danger">{errors.consignmentdate}</small>}
                                </div>
                            </div>


                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="shipFrom">Send By Office<span style={{ color: "red" }}>*</span></label>
                                    <select
                                        name="shipFrom"
                                        id="shipFrom"
                                        className={`form-control ${errors.shipFrom ? 'is-invalid' : ''}`}
                                        value={formData.shipFrom}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="" disabled>Select</option>
                                        {ourOffices.map((office) => (
                                            <option key={office.id} value={office.officeName}>
                                                {office.officeName}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.shipFrom && <small className="text-danger">{errors.shipFrom}</small>}
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="invoice">Invoice Number</label>
                                    <input
                                        type="text"
                                        id="invoice"
                                        name="invoice"
                                        className="form-control"
                                        value={formData.invoice}
                                        onChange={handleChange}
                                        placeholder="Invoice Number"
                                    />
                                </div>

                            </div>
                            <label htmlFor="">Ship To / Address: - <span style={{ color: "red" }}>*</span></label>
                            <div className="form-group">
                                <label>Address : <span style={{ color: "red" }}>*</span></label>
                                <input
                                    name="shiptoaddress"
                                    type="text"
                                    className={`form-control ${errors.shiptoaddress ? 'is-invalid' : ''}`}
                                    value={formData.shiptoaddress}
                                    onChange={handleChange}
                                    required
                                    placeholder="Address"
                                />
                                {errors.shiptoaddress && <small className="text-danger">{errors.shiptoaddress}</small>}
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label>State  <span style={{ color: "red" }}>*</span></label>
                                    <select
                                        name="state"
                                        id="state"
                                        className={`form-control ${errors.state ? 'is-invalid' : ''}`}
                                        value={formData.state}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="" disabled hidden>Select State</option>
                                        {states.map(state => (
                                            <option key={state.id} value={`${state.statename}`}>{state.statename}</option>
                                        ))}
                                    </select>
                                    {errors.state && <small className="text-danger">{errors.state}</small>}
                                </div>
                                <div className="form-group col-md-4">
                                    <label>City  <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="city"
                                        type="text"
                                        className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                                        value={formData.city}
                                        onChange={handleChange}
                                        required
                                        placeholder="City"
                                    />
                                    {errors.city && <small className="text-danger">{errors.city}</small>}
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Pincode </label>
                                    <input
                                        name="pincode"
                                        type="number"
                                        className="form-control"
                                        value={formData.pincode}
                                        onChange={handleChange}
                                        required
                                        placeholder="Pincode"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="ename">Dispatced By <span style={{ color: "red" }}>*</span></label>
                                <select
                                    name="ename"
                                    id="ename"
                                    className={`form-control ${errors.receiptBy ? 'is-invalid' : ''}`}
                                    value={formData.ename}
                                    onChange={handleChange}
                                    required
                                    placeholder="Employee Name "
                                >
                                    <option value="" >Select</option>
                                    {employees.map((employee) => (
                                        <option key={employee.id} value={employee.ename}>
                                            {employee.ename}
                                        </option>
                                    ))}
                                </select>
                                {errors.receiptBy && <small className="text-danger">{errors.receiptBy}</small>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="remark">Remark <span style={{ color: "red" }}>*</span></label>
                                <textarea
                                    id="remark"
                                    name="remark"
                                    className={`form-control ${errors.remark ? 'is-invalid' : ''}`}
                                    value={formData.remark}
                                    onChange={handleChange}
                                    placeholder="Enter any remarks"
                                    rows="3"
                                ></textarea>
                                {errors.remark && <small className="text-danger">{errors.remark}</small>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="photo">Upload Document</label>
                                <input
                                    type="file"
                                    id="photo"
                                    name="photo"
                                    className="form-control"
                                    onChange={(e) => handleImageChange(e, "photo")}
                                    required
                                />
                                <small>Max size : 200KB</small>
                            </div>
                        </div>
                        <div style={{ backgroundColor: "#00509D", color: "white" }} className="modal-footer">
                            <button type="submit" className="button_details" disabled={isLoading}>
                                {isLoading ? 'Loading...' : 'Submit'}
                            </button>
                            <button type="button" className="button_details" onClick={handleClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditOutward;












