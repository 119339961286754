import React, { useState, useEffect } from "react";
import axios from "axios";
import AddOutward from "./AddOutWard";
import OutwardDesc from "./OutwardDesc";
import EditOutward from './EditOutward';
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import SearchBar from "../../components/sidebar/SearchBar";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner'; // Spinner
import 'react-toastify/dist/ReactToastify.css';

function OutwardList({ handleLogout, username }) {
    const [outwards, setOutwards] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOutward, setSelectedOutward] = useState(null);
    const [showOutwardDetails, setShowOutwardDetails] = useState(false);
    const [isAddOutwardModalOpen, setIsAddOutwardModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editOutward, setEditOutward] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [deleteOutward, setDeleteOutward] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");

    useEffect(() => {
        fetchOutwards();
    }, []);

    const fetchOutwards = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/outwards`);
            setOutwards(response.data);
        } catch (error) {
            console.error("Error fetching outwards:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddOutward = () => {
        setIsAddOutwardModalOpen(true);
    };

    const handleCloseOutwardModal = () => {
        setIsAddOutwardModalOpen(false);
        setIsEditModalOpen(false);
    };

    const handleOutwardDetails = (outward) => {
        setSelectedOutward(outward);
        setShowOutwardDetails(true);
    };

    const handleEditOutwardClick = (outward) => {
        setEditOutward(outward);
        setSelectedOutward(outward);
        setIsEditModalOpen(true);
    };

    const handleEditOutwardClose = () => {
        setSelectedOutward(null);
    };

    const handleBackToTable = () => {
        setSelectedOutward(null);
        setShowOutwardDetails(false);
    };

    const handleDeleteOutward = (outward) => {
        setDeleteOutward(outward);
        setIsDeleteModalOpen(true);
    };

    const handleDeleteConfirmation = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/outwards/${deleteOutward.id}`);
            const deletedOutward = { ...deleteOutward, reason: deleteReason };
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedOutward);
            setOutwards(prevOutwards => prevOutwards.filter(outward => outward.id !== deleteOutward.id));
            setIsDeleteModalOpen(false);
            console.log("Outward deleted successfully");
        } catch (error) {
            console.error("Error deleting outward:", error);
        }
    };

    const handleUpdateOutwards = () => {
        toast.success("Successfully uploaded");
        fetchOutwards();
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = outwards.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className='d-flex w-100 h-100 bg-white'>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    {showOutwardDetails && selectedOutward ? (
                        <OutwardDesc
                            outward={selectedOutward}
                            onClose={handleBackToTable}
                        />) : (
                        <div className="row">
                            <div className="col-xl-12">
                                <div style={{ borderRadius: "20px", border: "1px solid #003459" }} className='overflow-hidden'>
                                    <div style={{ background: "rgb(33,131,128)", background: "#00509d " }} className="row no-gutters align-items-center p-3">
                                        <div className="col">
                                            <div className="text-xs font-weight-bold text-white text-uppercase d-flex align-items-center justify-content-between" style={{ fontSize: '1.5rem' }}>
                                                <div className="nunito text-white">Outward List</div>
                                                <button onClick={handleAddOutward} className="button_details"><i className="fa fa-plus"></i>  Add New Outward</button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='m-0 p-0' />
                                    <div className=''>
                                        <div className="card-body">
                                            <div className="" style={{ maxHeight: "610px", overflowY: "auto" }}>
                                                {isLoading ? (
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <ThreeDots color="#00509d" height={80} width={80} />
                                                    </div>
                                                ) : (
                                                    <table className="table table-bordered" style={{ width: "100%" }}>
                                                        <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                            <tr>
                                                                <th>client Name</th>
                                                                <th>Consignment No.</th>
                                                                <th>courier Company</th>
                                                                <th>Received Date</th>
                                                                <th>Consignment Subject</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <style>
                                                                {`.hyperlink:hover {color: blue;}`}
                                                            </style>
                                                            {currentItems.length === 0 ? (
                                                                <tr>
                                                                    <td colSpan="12" className="text-center">No Inquiry Entries.</td>
                                                                </tr>
                                                            ) : (
                                                                currentItems.map((outward, index) => (
                                                                    <tr key={index}>
                                                                        <td className='hyperlink' style={{ cursor: "pointer" }} onClick={() => handleOutwardDetails(outward)}> {outward.entityName}</td>
                                                                        <td>{outward.consignmentNo}</td>
                                                                        <td>{outward.courierCompany}</td>
                                                                        <td>{formatDate(outward.consignmentdate)}</td>
                                                                        <td>{outward.consignmentType}</td>
                                                                        <td>
                                                                            <div className="d-flex align-items-center justify-content-start gap-3 z-99">
                                                                                <div className="btn-group">
                                                                                    <button
                                                                                        className="button_action"
                                                                                        type="button"
                                                                                        data-toggle="dropdown"
                                                                                        aria-haspopup="true"
                                                                                        aria-expanded="false"
                                                                                    >
                                                                                        <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                                                                    </button>
                                                                                    <div className="dropdown-menu actionmenu">

                                                                                        <a className="dropdown-item" href="#" onClick={() => handleOutwardDetails(outward)}>
                                                                                            <i className="fa fa-file"></i> Detail
                                                                                        </a>
                                                                                        <a className="dropdown-item" href="#" onClick={() => handleEditOutwardClick(outward)}>
                                                                                            <i className="fas fa-edit"></i> Edit
                                                                                        </a>
                                                                                        <a className="dropdown-item" href="#" onClick={() => handleDeleteOutward(outward)}>
                                                                                            <i className="fa fa-trash"></i> Delete
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            )}
                                                        </tbody>
                                                    </table>
                                                )}
                                            </div>
                                            <ul className="pagination mt-2">
                                                <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                                </li>
                                                {Array.from({ length: Math.ceil(outwards.length / itemsPerPage) }, (_, i) => (
                                                    <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                        <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                                                    </li>
                                                ))}
                                                <li className={`page-item ${currentPage === Math.ceil(outwards.length / itemsPerPage) && 'disabled'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* {showOutwardDetails && selectedOutward && (
                                        <OutwardDesc
                                            outward={selectedOutward}
                                            onClose={handleBackToTable}
                                        />
                                    )} */}
                                    {selectedOutward && !showOutwardDetails && (
                                        <EditOutward outward={selectedOutward} onClose={handleEditOutwardClose} onUpdate={handleUpdateOutwards} />
                                    )}
                                    {isAddOutwardModalOpen && <AddOutward onClose={handleCloseOutwardModal} onUpdate={handleUpdateOutwards} />}
                                    <DeleteConfirmationModal
                                        isOpen={isDeleteModalOpen}
                                        itemName={deleteOutward ? deleteOutward.entityName : ""}
                                        onDelete={handleDeleteConfirmation}
                                        onClose={() => setIsDeleteModalOpen(false)}
                                        deleteReason={deleteReason}
                                        setDeleteReason={setDeleteReason}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default OutwardList;


















