import React, { useState, useEffect } from "react";
import axios from "axios";
import ProductDesc from "./ProductDesc";
import EditProduct from "./EditProduct";
import DeleteConfirmationModal from "../DeleteConfirmationModal"; // Import the new component
import SearchBar from "../../components/sidebar/SearchBar";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThreeDots } from 'react-loader-spinner'; // Spinner
import AddServiceProductModal from "../Services_Products/AddServiceProductModal";

function Productlist({ handleLogout, username }) {
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showProductDetails, setShowProductDetails] = useState(false);
    const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editProduct, setEditProduct] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [deleteProduct, setDeleteProduct] = useState([]); // State to store data of product being deleted
    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/service_product`);
            const Product = response.data.filter(item => item.type === "Product");
            setProducts(Product);
        } catch (error) {
            console.error("Error fetching products:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddProduct = () => {
        setIsAddProductModalOpen(true);
    };

    const handleCloseProductModal = () => {
        setIsAddProductModalOpen(false);
        setIsEditModalOpen(false);
    };

    const handleProductDetails = (product) => {
        setSelectedProduct(product);
        setShowProductDetails(true);
    };

    const handleEditProductClick = (product) => {
        setEditProduct(product);
        setSelectedProduct(product); // Update selectedProduct state
        setIsEditModalOpen(true);
    };

    const handleEditProductClose = () => {
        setSelectedProduct(null);
    };

    const handleBackToTable = () => {
        setSelectedProduct(null);
        setShowProductDetails(false);
    };

    const handleUpdateProduct = async (updatedProduct) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/products/${updatedProduct.id}`, updatedProduct);
            console.log("Product updated:", response.data);
            const updatedProducts = products.map(product => (product.id === updatedProduct.id ? response.data : product));
            setProducts(updatedProducts);
        } catch (error) {
            console.error("Error updating product:", error);
        }
    };

    const handleUpdateProducts = () => {
        toast.success("successfully uploaded");
        fetchProducts();
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = products.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // handle Delete 
    const handleDelete = async (product) => {
        try {
            await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/products/${product.id}`);
            setDeleteProduct(prevproducts => prevproducts.filter(item => item.id !== product.id));
            toast.success("service deleted successfully");
            fetchProducts();
        } catch (error) {
            console.error("Error deleting sales:", error);
        }
    };
    return (
        <div className='d-flex w-100 h-100 bg-white'>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    {!showProductDetails && (
                        <div className="row">
                            <div className="col-xl-12">
                                <div style={{ borderRadius: "20px", border: "1px solid #003459" }} className='overflow-hidden'>
                                    <div style={{ background: "rgb(33,131,128)", background: "#00509d " }} className="row no-gutters align-items-center p-3">
                                        <div className="col">
                                            <div className="text-xs font-weight-bold text-white text-uppercase d-flex align-items-center justify-content-between" style={{ fontSize: '1.5rem' }}>
                                                <div className="nunito text-white">Product List</div>
                                                <button onClick={handleAddProduct} className="button_details"><i className="fa fa-plus"></i> Add New Product</button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='m-0 p-0' />
                                    <div className=''>
                                        <div className="card-body">
                                            <div className="" style={{ maxHeight: "610px", overflowY: "auto" }}>
                                                {isLoading ? (
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <ThreeDots color="#00509d" height={80} width={80} />
                                                    </div>
                                                ) : (
                                                    <table className="table table-bordered" style={{ width: "100%" }}>
                                                        <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                            <tr>
                                                                <th>Product Name</th>
                                                                <th>Product Code</th>
                                                                <th>Remark</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <style>
                                                                {`.hyperlink:hover {color: blue;}`}
                                                            </style>
                                                            {currentItems.length === 0 ? (
                                                                <tr>
                                                                    <td colSpan="12" className="text-center">No Inquiry Entries.</td>
                                                                </tr>
                                                            ) : (
                                                                currentItems.map((product, index) => (
                                                                    <tr key={index}>
                                                                        <td>{product.name}</td>
                                                                        <td>{product.code}</td>
                                                                        <td>{product.remark || "-"}</td>
                                                                        <td>
                                                                            <div className="d-flex align-items-center justify-content-start gap-3 z-99">
                                                                                <div className="btn-group">
                                                                                    <button
                                                                                        className="button_action"
                                                                                        type="button"
                                                                                        data-toggle="dropdown"
                                                                                        aria-haspopup="true"
                                                                                        aria-expanded="false"
                                                                                    >
                                                                                        <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                                                                    </button>
                                                                                    <div className="dropdown-menu actionmenu">
                                                                                        <a className="dropdown-item" href="#" onClick={() => handleDelete(product)}>
                                                                                            <i className="fa fa-trash"></i> Delete
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            )}
                                                        </tbody>
                                                    </table>
                                                )}
                                            </div>
                                            <ul className="pagination mt-2">
                                                <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                                </li>
                                                {Array.from({ length: Math.ceil(products.length / itemsPerPage) }, (_, i) => (
                                                    <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                        <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                                                    </li>
                                                ))}
                                                <li className={`page-item ${currentPage === Math.ceil(products.length / itemsPerPage) && 'disabled'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {showProductDetails && selectedProduct && (
                                        <ProductDesc
                                            product={selectedProduct}
                                            onClose={handleBackToTable}
                                        />
                                    )}
                                    {selectedProduct && !showProductDetails && (
                                        <EditProduct product={selectedProduct} onClose={handleEditProductClose} onUpdate={handleUpdateProduct} />
                                    )}
                                    {isAddProductModalOpen && <AddServiceProductModal onClose={handleCloseProductModal} onUpdate={handleUpdateProducts} />}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Productlist;


