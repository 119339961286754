import React, { useState, useEffect } from 'react';

const SalesHistory = ({ salesDetails, onClose }) => {
    console.log(salesDetails);
    const handleClose = () => {
        onClose();
    };

    return (
        <div id="add" className="modal fade show" role="dialog" style={{ display: "block" }}>
            <div style={{ borderRadius: "20px" }} className="modal-dialog modal-lg overflow-hidden">
                <div className="modal-content">
                    <div style={{ backgroundColor: "#00509D", color: "white" }} className="modal-header">
                        <h5 className="modal-title">Sales History</h5>
                        <button type="button" className="button_details " onClick={handleClose}>
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                    <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                    <div className="modalmain">
                            <div>
                                <p><strong>Client Name:</strong> {salesDetails.clientName}</p>
                                <p><strong>Client Code:</strong> {salesDetails.clientCode}</p>
                                <p><strong>Address:</strong> {salesDetails.address || "-"}</p>
                                <p><strong>Comapny  Name:</strong> {salesDetails.companyName || "-"}</p>
                                <p><strong>Email:</strong> {salesDetails.emailId1 || "-"}</p>
                                <p><strong>Mobile Number:</strong> {salesDetails.mobileNo1 || "-"}</p>
                                <p><strong>sales Date:</strong> {salesDetails.salesDate || "-"}</p>
                                <p><strong>sales Description:</strong> {salesDetails.salesDescription || "-"}</p>
                                <p><strong>sales Executive Name:</strong> {salesDetails.salesExecutiveName}</p>
                                <p><strong>Selected Service Products:</strong> {salesDetails.selectedServiceProducts && JSON.parse(salesDetails.selectedServiceProducts).map(product => product.name).join(', ')}</p>
                                <p><strong>Total Sales Amount:</strong> {salesDetails.totalSalesAmount}</p>
                            </div>
                            <div style={{ width: "15vw", height: "30vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <img style={{ width: "100%", height: "100%", objectFit: "contain" }} src={`${process.env.REACT_APP_LOCAL_URL}/uploads/sales_photo/${salesDetails.uploadPicture}`} alt="Sales Upload" />
                            </div>
                            </div>
                        </div>
                    <div style={{ backgroundColor: "#00509D", color: "white" }} className="modal-footer">
                        <button type="button" className="button_details" onClick={handleClose}>Close</button>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default SalesHistory;
