import React from 'react'

const EditService = () => {
  return (
    <div>
      <h1>Edit Service</h1>
    </div>
  )
}

export default EditService
