import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from 'react-select';
import imageCompression from 'browser-image-compression';

const AddPurchase = ({ onClose, onUpdate }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        vendorName: "", // Selected vendor name from dropdown
        vendorCode: "", // Vendor code fetched from database based on selected vendor name
        vendorAddress: "", // Vendor address fetched from database based on selected vendor name
        purchaseDate: "", // Purchase date
        invoiceNumber: "", // Invoice number
        purchaseItems: [], // Purchase items fetched from database
        purchaseAmount: "", // Purchase amount
        paymentMode: "", // Payment mode
        purchaseDescription: "", // Purchase description
        purchasedBy: "", // Purchased by employee fetched from database
        purchasedApprovedBy: "", // Purchased approved by employee fetched from database
        upload1: null, // Upload field 1
        upload2: null, // Upload field 2
        remark: "", // Remark
        username:localStorage.getItem('username'),
    });

    const [vendors, setVendors] = useState([]);
    const [services, setServices] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        fetchVendors();
        fetchServices();
        fetchEmployees();
    }, []);

    const fetchVendors = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/vendors`);
            setVendors(response.data);
        } catch (error) {
            console.error('Error fetching Vendors:', error);
        }
    };

    const fetchServices = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/service_product`);
            setServices(response.data);
        } catch (error) {
            console.error('Error fetching Services:', error);
        }
    };

    const fetchEmployees = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
            setEmployees(response.data);
        } catch (error) {
            console.error('Error fetching Employees:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "vendorName") {
            const selectedVendor = vendors.find(vendor => vendor.vendorName === value);
            setFormData({
                ...formData,
                [name]: value,
                vendor_id:selectedVendor ? selectedVendor.id : "",
                vendorCode: selectedVendor ? selectedVendor.vendorCode : "",
                vendorAddress: selectedVendor ? `${selectedVendor.address}, ${selectedVendor.city}, ${selectedVendor.state}, ${selectedVendor.pincode}` : "",
            });
        } else if (name === "purchasedBy" || name === "purchasedApprovedBy") {
            const selectedEmployee = employees.find(employee => employee.ename === value);
            setFormData({
                ...formData,
                [name]: value,
                [`${name}Id`]: selectedEmployee ? selectedEmployee.id : "",
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleImageChange = async (e, uploadField) => {
        const file = e.target.files[0];
        try {
            const options = {
                maxSizeMB: 0.2,
                maxWidthOrHeight: 800,
                useWebWorker: true,
            };

            const compressedFile = await imageCompression(file, options);
            const fileWithMetaData = new File([compressedFile], file.name, { type: compressedFile.type });

            setFormData({
                ...formData,
                [uploadField]: fileWithMetaData,
            });
        } catch (error) {
            console.error('Image compression error:', error);
        }
    };
    const handleSupplierChange = (selectedOptions) => {
        setFormData({
            ...formData,
            purchaseItems: selectedOptions.map(option => ({ id: option.value, name: option.label })),
        });
    };

    const validate = () => {
        let formErrors = {};
        if (!formData.vendorName) formErrors.vendorName = 'Vendor Name is required';
        if (!formData.vendorCode) formErrors.vendorCode = 'Vendor Code is required';
        if (!formData.vendorAddress) formErrors.vendorAddress = 'Vendor Address is required';
        if (!formData.purchaseDate) formErrors.purchaseDate = 'Purchase Date is required';
        if (!formData.purchaseItems || formData.purchaseItems.length === 0) {
            formErrors.purchaseItems = 'Supplier of Service/Product is required';
        }
        if (!formData.purchaseAmount) formErrors.purchaseAmount = 'Purchase Amount required';
        if (!formData.paymentMode) formErrors.paymentMode = 'Payment Mode is required';
        if (!formData.purchasedBy) formErrors.purchasedBy = 'Purchased By is required';
        if (!formData.purchasedApprovedBy) formErrors.purchasedApprovedBy = 'Purchased Approved By is required';

    
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (!validate()) {
            return;
          }
      
        try {
            const formDataToSend = new FormData();
            for (const key in formData) {
                if (formData.hasOwnProperty(key)) {
                    if ((key === "upload1" || key === "upload2") && formData[key]) {
                        formDataToSend.append(key, formData[key]);
                    } else if (key === "purchaseItems") {
                        formDataToSend.append(key, JSON.stringify(formData[key]));
                    } else {
                        formDataToSend.append(key, formData[key]);
                    }
                }
            }

            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/addpurchase`, formDataToSend);
            console.log("Data uploaded successfully:", response.data);
            onUpdate();
            setTimeout(() => {
                onClose();
                window.location.reload(); // Reload the page after submission
            }, 1000);
        } catch (error) {
            console.error("Error uploading data:", error);
        }finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div
            id="addPurchase"
            className="modal fade show"
            role="dialog"
            style={{ display: "block" }}
        >
            <div style={{ borderRadius: "20px" }} className="modal-dialog modal-lg overflow-hidden">
                <div className="modal-content">
                    <form
                        action=""
                        id="formAddPurchase"
                        autoComplete="off"
                        noValidate="novalidate"
                        onSubmit={handleSubmit}
                    >
                         <div style={{ backgroundColor: "#00509D", color: "white" }} className="modal-header">
                            <h5 className="modal-title">Add Purchase</h5>
                            <button type="button" className="button_details " onClick={handleClose}>
                             <i className="fa-solid fa-xmark"></i>
                         </button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                            <div className="row">
                                <div className="col-md-12">
                                    {/* Purchase details */}
                                    <div className="form-row">
                                        <div className="form-group col-md-8">
                                            <label>Vendor Name <span style={{ color: "red" }}>*</span></label>
                                            <select
                                                name="vendorName"
                                                id="vendorName"
                                                className={`form-control ${errors.vendorName ? 'is-invalid' : ''}`}
                                                value={formData.vendorName}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="" disabled hidden>Select vendor</option>
                                                {vendors.map((vendor) => (
                                                    <option key={vendor.id} value={vendor.vendorName}>
                                                        {vendor.vendorName}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.vendorName && <small className="text-danger">{errors.vendorName}</small>}
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Vendor Code  <span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="vendorCode"
                                                type="text"
                                                className={`form-control ${errors.vendorCode ? 'is-invalid' : ''}`}
                                                placeholder="Vendor Code"
                                                value={formData.vendorCode}
                                                readOnly
                                            />
                                            {errors.vendorCode && <small className="text-danger">{errors.vendorCode}</small>}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Vendor Address  <span style={{ color: "red" }}>*</span></label>
                                        <textarea
                                       placeholder="Vendor Address"
                                            rows="1" cols="50"
                                            name="vendorAddress"
                                            className={`form-control ${errors.vendorAddress ? 'is-invalid' : ''}`}
                                            value={formData.vendorAddress}
                                            readOnly
                                        ></textarea>
                                        {errors.vendorAddress && <small className="text-danger">{errors.vendorAddress}</small>}
                                    </div>
                                    <hr />
                                    <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>Purchase Date <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="purchaseDate"
                                            type="date"
                                            className={`form-control ${errors.purchaseDate ? 'is-invalid' : ''}`}
                                            value={formData.purchaseDate}
                                            onChange={handleChange}
                                            required
                                        />
                                        {errors.purchaseDate && <small className="text-danger">{errors.purchaseDate}</small>}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Invoice Number </label>
                                        <input
                                            name="invoiceNumber"
                                            type="text"
                                            className="form-control"
                                            value={formData.invoiceNumber}
                                            onChange={handleChange}
                                            required
                                            placeholder="Invoice No."
                                        />
                                    </div>
                                    </div>
                                    <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>Purchase Items  <span style={{ color: "red" }}>*</span></label>
                                        <Select
                                                isMulti
                                                options={services.map(service => ({ value: service.id, label: service.name }))}
                                                onChange={handleSupplierChange}
                                            />
                                             {errors.purchaseItems && <small className="text-danger">{errors.purchaseItems}</small>}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Purchase Amount <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="purchaseAmount"
                                            type="number"
                                            className={`form-control ${errors.purchaseAmount ? 'is-invalid' : ''}`}
                                            value={formData.purchaseAmount}
                                            onChange={handleChange}
                                            required
                                            placeholder="Purchase Amount"
                                        />
                                        {errors.purchaseAmount && <small className="text-danger">{errors.purchaseAmount}</small>}
                                    </div>
                                    </div>
                                   
                                    <div className="form-group">
                                        <label>Payment Mode  <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="paymentMode"
                                            type="text"
                                            className={`form-control ${errors.paymentMode ? 'is-invalid' : ''}`}
                                            value={formData.paymentMode}
                                            onChange={handleChange}
                                            placeholder="Payment Mode"
                                        />
                                        {errors.paymentMode && <small className="text-danger">{errors.paymentMode}</small>}
                                    </div>
                                    <div className="form-group">
                                        <label>Purchase Description</label>
                                        <textarea
                                            name="purchaseDescription"
                                            className="form-control"
                                            value={formData.purchaseDescription}
                                            onChange={handleChange}
                                            placeholder="Purchase Description"
                                        ></textarea>
                                    </div>
                                    <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="purchasedBy">Purchased By  <span style={{ color: "red" }}>*</span></label>
                                        <select
                                            name="purchasedBy"
                                            id="purchasedBy"
                                            className={`form-control ${errors.purchasedBy ? 'is-invalid' : ''}`}
                                            value={formData.purchasedBy}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="">Select</option>
                                            {employees.map(employee => (
                                                <option key={employee.id} value={employee.ename}>
                                                    {employee.ename}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.purchasedBy && <small className="text-danger">{errors.purchasedBy}</small>}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="purchasedApprovedBy">Purchased Approved By <span style={{ color: "red" }}>*</span></label>
                                        <select
                                            name="purchasedApprovedBy"
                                            id="purchasedApprovedBy"
                                            className={`form-control ${errors.purchasedApprovedBy ? 'is-invalid' : ''}`}
                                            value={formData.purchasedApprovedBy}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="">Select</option>
                                            {employees.map(employee => (
                                                <option key={employee.id} value={employee.ename}>
                                                    {employee.ename}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.purchasedApprovedBy && <small className="text-danger">{errors.purchasedApprovedBy}</small>}
                                    </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea
                                            name="remark"
                                            className="form-control"
                                            value={formData.remark}
                                            onChange={handleChange}
                                            placeholder="Remark"
                                        ></textarea>
                                    </div>                                    
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>Purchase Invoice Upload </label>
                                            <input
                                                name="upload1"
                                                type="file"
                                                className="form-control-file"
                                                onChange={(e) => handleImageChange(e, "upload1")}
                                                required
                                            />
                                            <small>Max Size: 200KB</small>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Upload Document</label>
                                            <input
                                                name="upload2"
                                                type="file"
                                                className="form-control-file"
                                                onChange={(e) => handleImageChange(e, "upload2")}
                                            />
                                            <small>Max Size: 200KB</small>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div style={{ backgroundColor: "#00509D", color: "white" }} className="modal-footer">
                       <button type="submit" className="button_details" disabled={isLoading}>
                           {isLoading ? 'Loading...' : 'Submit'}
                       </button>
                       <button type="button" className="button_details" onClick={handleClose}>Close</button>
                   </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddPurchase;
